@use "../../../../styles/common";

.item {
    display: flex;
    position: relative;
    gap: 6px;
    transition: all 150ms ease;
    cursor: pointer;
    container-name: item;
    container-type: inline-size;
    background: common.$white;
    padding: 6px 16px;

    &:hover {
        background: common.$ct-gray-175;
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;
    }

    &__thumb {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background: common.$ct-yellow-1;
        width: 30px;
        height: 30px;
        color: common.$white;
    }

    &__title {
        color: common.$ct-black-50;
        font-size: common.$fz-14;
        line-height: 1rem;

        &_muted {
            color: common.$ct-gray-600;
        }
    }

    &__id,
    &__role {
        color: common.$ct-gray-600;
        font-size: common.$fz-13;
        line-height: common.$fz-15;
    }
}

@container item (width > 500px) {
    .item__role {
        position: absolute;
        top: 6px;
        right: 16px;
        width: 100px;
    }
}
