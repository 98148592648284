@use "../../styles/common";

.queues {
  @include common.page;
  padding: 20px 10px 25px 10px;

  &__default {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  &__defaultIcon {
    color: common.$text-muted;
    margin-bottom: 15px;
  }

  &__defaultMessage {
    color: common.$text-muted;
    text-align: center;
    font-size: common.$fz-18;
    margin-bottom: 20px;
  }

  &__sidebar {
    width: 450px;

    &_closed {
      margin-right: -1 * 550px;
    }
  }
}
