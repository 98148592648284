@use '../../styles/common';

.dialogTransferModal {
    display: flex;
    min-height: 100%;
    justify-content: center;
    align-items: center;

    &__tree {
        height: calc(100vh - 302px);
        padding: 0 15px;
        overflow-y: auto;

        :global(.category_selected) {
            background: none;
        }
    }

    &__window {
        position: relative;
        width: 640px;
        height: calc(100vh - 100px);
        overflow: hidden;
    }

    &__operatorsContainer {
        height: calc(100vh - 302px);
        overflow-y: auto;
        padding: 0 15px;
    }

    &__formRow {
        margin: 0 0 6px;

        &_double {
            margin: 0 0 12px;
        }
    }

    &__textarea {
        textarea {
            resize: none;
        }
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        position: absolute;
        bottom: 20px;
        right: 20px;

        &_centered {
            display: flex;
            justify-content: center;
        }
    }

    &__btn {
        &_extended {
            width: 95%;
            position: absolute;
            bottom: 20px;
        }
    }
}

.dialogTransferOperatorSearch {
    display: flex;
    flex-flow: column;
    position: relative;

    &__error {
        font-size: 0.75rem;
        color: common.$ct-red-1;
        margin: 4px 0 0;
    }
}