@import "../../../../styles/common";

.react-flow {
  .agent-node-outputs {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &__output {
      position: relative;
      padding-bottom: 5px;
      font-size: $ct-font-size-base * 0.875;

      &_disabled {
        @include text-muted;
      }
    }

    &__output-handle {
      right: -26px;
      background-color: $scenario-node-controls-color;
      height: $scenario-node-handle-size;
      width: $scenario-node-handle-size;

      &.connectable {
        cursor: pointer;
      }
    }
  }
}