@use "../../../styles/common.scss";

.closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
