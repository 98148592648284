@import "../../styles/common";

$edge-path-color: $scenario-node-controls-color;
$edge-icon-color: $ct-gray-600;
$edge-icon-bg-color: $ct-gray-175;

.scenario-editor {
  height: 100%;
  width: 100%;

  &__body {
    width: 100%;
    height: 100%;
  }

  .react-flow {
    &__edge-path {
      stroke-width: 2;

      &_hidden {
        stroke: transparent !important;
        stroke-width: 25;
      }
    }

    &__edge-icon {
      @include transition-all();

      width: 30px;
      height: 30px;
      fill: $edge-icon-bg-color;
      color: $edge-icon-color;
    }

    &__edge-icon-wrapper {
      opacity: 0;
      z-index: 10;
      transition: opacity 2.5s ease;

      &:hover {
        @include transition-all();
        cursor: pointer;
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &_not-editable {
    .react-flow {
      &__node {
        cursor: initial;
      }
    }
  }

  .react-flow__edge {
    &:hover .react-flow__edge-icon-wrapper {
      @include transition-all();
      opacity: 1;
      pointer-events: all;
    }
  }
}
