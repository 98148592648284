@import "../../styles/common";

$operator-status-default-color: $body-color;

.operatorStatusSelector {
    display: flex;
    height: inherit;

    &_sidebar {
        margin-bottom: $menu-item-bottom-spacing * 2;
    }

    $self: &;

    &.sidebarContextMenu {
        background-color: transparent;
        padding: 0;

        &:hover {
            background-color: transparent;
        }
    }

    &__toggle {
        @include disable-default-button-styles;
    }

    &_inHeader {
        @include flex-center-row;
        align-items: center;
        gap: 5px;
        min-width: 110px;
    }

    &__container {
        align-items: center;
        justify-content: center;
    }

  &__item {
    display: flex;
    align-items: center;
    font-size: $fz-14;

    > div:first-child {
      margin-right: 0;
    }

        #{$self}__circle {
            background-color: $operator-status-default-color;
        }
    }

    &__selectedIcon {
        margin-left: 5px;
    }

    &__circle {
        padding: 4px;
        border-radius: 50%;
        border: 1px solid white;
        background-color: $operator-status-default-color;
    }

    &__context {
        &.context-menu {
            padding: 12px;
        }
    }

    &__spinner {
        color: white;
        width: 20px;
        height: 20px;
    }
}
