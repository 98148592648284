@use "../../../styles/common" as common;

$task-card-horizontal-padding: 12px;

.operatorTask {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
  border-bottom: 1px solid lightgray;
  cursor: pointer;

  @include common.transition-all();

  &:hover {
    background-color: common.$ct-gray-175;
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex: 2 1;
    gap: 6px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__channelLogo {
    position: relative;
    top: 5px
  }

  &__channelInfo {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    color: common.$ct-gray-600;
    font-size: common.$fz-12;
  }

  &__preview {
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
  }
}