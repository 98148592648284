@use "../../styles/common";

$item-color: common.$ct-gray-650;
$item-hover-color: darken($item-color, 10%);

.wrap {
    $self: &;
    position: relative;
    flex: 1 1 auto;
}

.left {
    display: flex;
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 15px;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding: 3px;
    height: 24px;
    color: $item-color;
    @include common.transition-all();

    &:hover {
        color: $item-hover-color;
    }

    & &__icon {
        @include common.img-size(18px);
    }
}

.right {
    $right: &;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;

    &_clear {
        cursor: pointer;
        color: $item-color;
        @include common.transition-all();

        #{$right}__icon {
            font-size: common.$fz-18;
        }

        &:hover {
            color: $item-hover-color;
        }
    }

    &__loading {
        width: 18px;
        height: 18px;
    }
}

.input {
    &:global(.form-control) {
        padding-right: 40px;
        padding-left: 48px;

        &:disabled {
            border-color: #e6e6e6;
            background-color: common.$ct-gray-175;
        }
    }

    &_withLabel {
        &:global(.form-control) {
            padding-left: 120px;
        }
    }
}
