@import "../../styles/common";

$article-type-width: 180px;
$article-type-margin-right: 25px;

.article-type {
  @include text-truncate();

  padding: 5px;
  width: $article-type-width;
  box-sizing: content-box;
  margin-right: $article-type-margin-right;

  &:empty:before {
    content: "e";
    visibility: hidden;
  }
}

.article-type-select {
  width: auto;
  display: inline;
  margin-right: $article-type-margin-right;

  &__toggle {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: $article-type-width;
  }

  &__text {
    @include text-truncate();
    padding-right:10px;
  }

  &__icon {
    font-size: 20px;
    line-height: 20px;
    float: right;
  }

  &__menu {
    @include mini-scrollbar();

    max-height: 250px;
    overflow-y: auto;
  }

  &__item {
    .menu-item-row__title {
      @include text-truncate();
      width: $article-type-width - 10px;
    }
  }
}