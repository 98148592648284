@import "../../styles/common";

$toolbar-item-font-size: $ct-font-size-base * 1.14;

.articleToolbar {
    display: flex;

    &__item {
        @include article-action();
        font-size: $toolbar-item-font-size;
        padding: 10px 0;
        margin-right: 30px;
        color: $knowledge-base-main-text;

        &:hover {
            text-decoration: none;
            color: $knowledge-base-primary-text;
        }
    }

    &__number {
        text-decoration: line-through;
        text-decoration-color: $ct-red-4;
        margin: 0 .25rem 0 .25rem;
    }

    &__icon {
        margin-right: 10px;
    }

    &_removed {
        color: $ct-red-4;
    }

}