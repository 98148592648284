$font-family-sans-serif: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$ct-font-size-base: 14px;

// highlight box-shadow
$highlight-box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
$medium-highlight-box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
$small-highlight-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.10);

// box-shadows
$hover-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
$page-box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.05);

// spacing
$sidebar-vertical-space: 25px;

// Project image size
$project-image-height: 30px;
$project-image-weight: 30px;

// Sidebar collapse breakpoints
$sidebar-attached-first-breakpoint: 1440px;
$sidebar-attached-second-breakpoint: 1200px;
$sidebar-attached-third-breakpoint: 768px;

// content height under main header
$height-without-header: calc(100vh - var(--header-height));