
.infoField {
    margin-bottom: 10px;
    &__name {
        font-weight: 500;
        margin-bottom: 4px;
    }

    &__value {
        display: flex;
    }
}