@use "../../../../../styles/common";

$width: 320px;
$title-font-size: common.$ct-font-size-base * 1.28;
$icon-size: 42px;

.quickAction {
  @include common.card-border;
  padding: 20px 20px 16px;
  width: $width;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__icon {
    font-size: $icon-size;
    height: $icon-size;
    width: fit-content;
  }

  &__title {
    margin-top: 20px;
    font-size: $title-font-size;
    line-height: 21px;
  }

  &__subtitle {
    @include common.text-muted;
    margin-top: 8px;
    margin-bottom: 20px;
    font-size: common.$ct-font-size-base;
    line-height: 16px;
  }

  &__btn {
    justify-self: flex-end;
    margin-top: auto;
  }
}
