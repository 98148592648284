@import "../../styles/common";

.scenarioArticle {
    @include article-view;

    &__editor {
        height: 100vh;
        padding: 0 20px;
    }

    &_new-workplace {
        min-width: auto;
    }
}
