@use "../../../../styles/common";

.item {
    display: flex;
    align-items: center;
    width: 100%;
    height: var(--header-height);
    user-select: none;

    &__title {
        @include common.no-wrap-text();
    }
}
