@use "../../styles/common";

.draggingArea {
    position: relative;

    &__dropPlace,
    &__dropPlaceContent {
        position: absolute;
        inset: 0;
    }

    &__dropPlaceContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: common.$ct-gray-600;
        background-color: #fff;

        &__icon {
            margin-bottom: 10px;
        }

        &__text {
            font-size: 18px;
        }
    }
}