@use "../../styles/common";

$external-link-color: var(--link);

.externalLink {
  display: flex;
  margin-bottom: 14px;
  cursor: pointer;
  color: $external-link-color;

  &__icon {
    margin-right: 10px;
    margin-top: 3px;
  }
}
