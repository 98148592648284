@use "../../styles/common";

.custom-select {
    &__control {
        @include common.enable-focus-within;
        outline: 0 none;
        min-height: 40px !important;
        height: 40px;
    }

    &__value-container {
        padding: common.$input-padding-y common.$input-padding-x !important;
        line-height: 1rem * 18 / 16;
        height: 100%;
        cursor: text;
    }

    &__indicator-separator {
        display: none;
    }

    &__indicator {
        padding: 0 !important;
    }

    &__indicators {
        margin-right: 8px;
    }

    &__dropdown-indicator {
        color: common.$input-color !important;
        width: 24px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__value-container > div {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
    }

    &__menu {
        z-index: 4 !important;
    }

    &__menu &__option {
        line-height: 12px;
        cursor: pointer;

        &:hover,
        &--is-focused {
            background-color: common.$ct-gray-175 !important;
        }

        &--is-selected {
            background-color: common.$white !important;
            color: inherit !important;
        }
    }

    &__spinner {
        margin-right: 8px;
        color: common.$gray-500;
    }

    &_invalid &__control {
        border-color: common.$red;
    }

    &_invalid ~ .invalid-feedback {
        display: block;
    }
}
