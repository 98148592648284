@import "../../styles/common";

.settings-table-loader {
    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    }

    &__title {
        height: 30px;
        width: 200px;
    }

    &__search {
        height: 35px;
        flex: 1 1 auto;
        margin-bottom: 10px;
    }

    &__btn-add {
        height: 38px;
        flex: 0 0 auto;
        width: 146px;
        margin-left: 24px;
    }

    .table {
        &__head {
            display: flex;
            justify-content: space-between;
        }

        &__column {
            height: 50px;
            flex: 1 1;
            margin-right: 4px;
            margin-bottom: 10px;
            padding: 10px 0;

            &:last-child {
                margin-right: 0;
            }
        }

        &__row {
            height: 50px;
            margin-bottom: 10px;
            padding: 10px 0;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
