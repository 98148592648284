@use "../../styles/common";

.portal {
    &__wrap {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.65);
        display: none;
        visibility: hidden;
        z-index: 10;

        &_shown {
            display: block;
            visibility: visible;
            z-index: 1000;
        }
    }
}