@use "../../styles/common";

$duration-max-width: 110px;

.operatorCard {
    cursor: pointer;

    &__info {
        font-size: common.$fz-14;
        line-height: 1rem;
    }

    &__status {
        display: flex;
        margin-top: 6px;
    }

    &__id {
        margin-top: 6px;
        color: common.$ct-gray-600;
        font-size: common.$fz-13;
        line-height: common.$fz-15;
    }

    &__item {
        display: inline-block;
        margin-right: 5px;
        padding: 0 4px;
    }

    &__duration {
        @include common.no-wrap-text();
        max-width: $duration-max-width;
        color: common.$text-muted;
        font-size: common.$menu-item-title-font-size;
    }
}
