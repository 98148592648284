@import "../../styles/common";

.article-tags {
  @include mini-scrollbar();
  @include no-outline-focus();

  border: none;
  resize: none;
  flex: 1;
  font-size: $font-size-base;
  word-spacing: 1rem;
  padding-top: 5px;

  &.is-invalid {
    color: $red;
  }
}