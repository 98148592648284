// colors
$primary: $ct-blue;
$secondary: $ct-orange;
$body-color: $ct-gray-850;
$selected: $ct-blue;
$text-muted: $ct-gray-650;
$light: $ct-gray-175;

// variables
$font-size-base: $ct-font-size-base / 16px * 1rem;

$input-padding-x: 1rem * 15 / 16;
$input-padding-y: 1rem * 10 / 16;
$input-height: 40px;
