@import "../../styles/common";

@mixin compareModTitle {
    margin-right: 1rem;
}

.articleHeader {
    @include article-section;
    padding-top: 70px;

    &__headerSection {
        display: flex;
        align-items: center;

        &:first-child {
            margin-bottom: 20px;
        }
    }

    &__headerRight {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
    }

    &__headerTop {
        display: flex;
        margin-bottom: 20px;
        align-items: flex-start;
    }

    &__title {
        display: flex;
        flex: 1;
        font-size: $knowledge-base-article-title-font-size;
        font-weight: bold;
        margin-right: 20px;
        flex-direction: column;
        max-width: calc(100vw - 1200px);
        overflow-wrap: break-word;

        &_expanded {
            max-width: calc(100vw - 650px);
            overflow-wrap: break-word;
        }

        &_added {
            @include compareModTitle;
            color: $ct-blue;
        }

        &_removed {
            @include compareModTitle;
            text-decoration: line-through;
            text-decoration-color: #d22323;
        }

        &_unmodified {
            @include compareModTitle;
        }
    }

    &_expanded {
        @include article-section-expanded;
    }
}
