@use "../../styles/common";

$header-height: 40px;
$settings-size: 56px;
$dynamic-width-based-on-left-menu: calc(100vw - 90px);
$first-breakpoint: common.$sidebar-attached-first-breakpoint;
$second-breakpoint: common.$sidebar-attached-second-breakpoint;
$third-breakpoint: common.$sidebar-attached-third-breakpoint;

@function change-sidebar-width($left-sidebar-width) {
    @return calc(100vw - 90px - #{$left-sidebar-width});
}

@mixin full-height {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.sidebarRight {
    display: flex;
    flex-direction: column;
    padding: common.$sidebar-vertical-space 0;
    box-shadow: common.$page-box-shadow;
    @include common.transition-all();
    position: fixed;
    overflow: hidden;
    width: common.$sidebar-right-width;
    background-color: common.$body-bg;
    bottom: 0;
    right: 0;
    height: common.$height-without-header;
    z-index: 999;
    $self: &;

    &_closed {
        margin-right: -1 * common.$sidebar-right-width;

        @media (max-width: $first-breakpoint) {
            margin-right: -1 * $first-breakpoint;
        }

        @media (max-width: $second-breakpoint) {
            margin-right: -1 * $second-breakpoint;
        }

        @media (max-width: $third-breakpoint) {
            margin-right: -1 * $third-breakpoint;
        }
    }

    @media (max-width: ($third-breakpoint - 1px)) {
        width: $dynamic-width-based-on-left-menu;
    }

    @media (min-width: $third-breakpoint) and (max-width: $second-breakpoint) {
        width: change-sidebar-width(360px);
    }

    @media (min-width: ($second-breakpoint + 1px)) and (max-width: $first-breakpoint) {
        width: change-sidebar-width(600px);
    }

    @media (max-width: 1600px) {
        width: common.$sidebar-right-width-collapsed;
    }

    &_attached {
        width: 100%;
        position: relative;
        box-shadow: none;
        #{$self}_closed {
            margin-right: auto;
        }
    }

    &__header {
        font-size: common.$page-title-font-size;
        margin-bottom: 25px;
        min-height: $header-height;
        padding: 0 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__title {
        padding-left: 16px;
        @include common.no-wrap-text();

        &_adjusted {
            margin-right: $settings-size;
        }
    }

    &__settings {
        font-size: common.$fz-14;
        margin-left: auto;
    }

    &__settings,
    & &__icon {
        width: 38px;
        height: 38px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    & &__icon_right {
        margin-left: auto;
    }

    &__buttonsContainer {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    &__actionBtnRight {
      margin-left: auto;
    }

    &__content {
        @include full-height();
        overflow-x: auto;

        :global(.tab__content) {
            @include full-height();
        }

        :global(.tab__content) {
            > :global(div.active) {
                @include full-height();
            }
        }
    }
}

.separator {
    padding: 0;
    margin: 0;
    height: 1px;
    border-bottom: 1px solid common.$ct-gray-300;
}
