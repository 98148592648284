@import "../../styles/common";

.catalog-popover {
  z-index: 1060;
  width: 450px;
  max-width: 450px;

  &__add-catalog-icon {
    margin: 0;
  }

  .popover-body {
    padding: 20px;
  }
}
