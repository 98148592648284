@import "../../../styles/common";

.slot-form {
    @include page-layout-sidebar;
    @include sidebar-form;

    &__textarea {
        textarea {
            min-height: 80px;
        }
    }

    &__checkbox {
        &_disabled {
            pointer-events: none;

            > label::before {
                opacity: 0.6;
            }
        }
    }

    .custom-checkbox {
        margin-bottom: 1rem;
    }
}