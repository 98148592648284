@import "../../styles/common";

.user-access-form {
  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__cancel {
    margin-right: 15px;
  }

  .form-label {
    font-size: $knowledge-base-form-font-size;
  }
}