@use "../../../styles/common";

.formSection {
  padding-bottom: 1rem;

  &_border {
    @include common.section-border;
  }

  &_indent {
    margin-top: 0.5rem;
  }
}
