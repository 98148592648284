@import "../../styles/common";

$choose-slot-form-icon-size: 32px;

.choose-slot-form {
  @include page-layout-sidebar();

  &__content {
    padding: 10px 20px;
  }

  &__item {
    @include transition-all;
    padding: 14px;
    cursor: pointer;
    margin-bottom: 16px;
    border: 1px solid $ct-gray-300;
    border-radius: 5px;
    display: flex;

    &:hover {
      box-shadow: $medium-highlight-box-shadow;
    }
  }

  &__create-btn {
    margin: 10px 20px;
    flex: initial;
  }

  &__search {
    margin: 10px 20px 20px;
    flex: initial;
  }
}