@import "../../styles/common";

.queue-card-loader {
    padding: 41px 0 20px 0;

    &__items {
        display: flex;
        justify-content: space-between;
        padding: {
            top: 20px;
            bottom: 20px;
            left: 35px;
            right: 35px;
        };
    }

    &__item {
        width: 150px;
        height: 69px;
    }
}