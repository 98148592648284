@import "../../styles/common";

$popover-bg-color: $body-bg;

.popover {
  &-trigger {
    &:global(.popover) {
      z-index: 4000;
      max-width: 100%;
      border-width: 0;
    }

    width: 290px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 4px 32px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    border: none;
    background-color: $popover-bg-color;
  }

  &-header {
    background-color: $popover-bg-color;
  }
}
