.dropdownPlicked {
    position: relative;

    &__header {
        display: flex;
        align-items: center;
        padding: 0 16px;
        height: 56px;
        min-width: 315px;
        border-radius: 8px;
        column-gap: 11px;
        background: #fff;
        border: 1px solid #E0E0E0;
        font-size: 1.125rem;
        cursor: pointer;
        transition: all 150ms ease;
        user-select: none;

        &:hover {
            background: rgb(236, 236, 236)
        }
    }

    &__overlay {
        position: absolute;
        display: none;
        flex-flow: column;
        padding: 0 16px 16px;
        top: -1px;
        left: 0;
        right: 0;
        background: #fff;
        min-height: 100%;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        pointer-events: all;

        &_header {
            border: none;
            padding: 0;
            min-width: auto;

            &:hover {
                background: transparent
            }
        }

        &_nonclickable {
            pointer-events: none;

            span {
                font-size: 1rem;
            }
        }
    }

    &_selected {
        .dropdownPlicked__header {
            border-color: transparent;
        }

        .dropdownPlicked__overlay {
            display: flex;
        }
    }
}