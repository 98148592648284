@use "./../../styles/common";

.queueOperatorsTable {
  :global(.settings-table__body) {
    thead th, tbody td {
      font-size: common.$fz-14;
    }
  }

  display: flex;
  flex-direction: column;
  gap: 14px;

  &__searchInput > input {
    height: 40px;
    border-color: common.$ct-gray-600;
  }

  &__statusContainer {
    padding-top: 5px;
  }

  &__status {
      display: inline-block;
      margin-right: 5px;
      padding: 0 4px;
  }

  &__duration {
    padding-top: 10px;
  }

  &__settings {
    align-items: flex-start;
  }

  &__rowButton {
    padding-top: 0;
  }

  &_empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;

    color: common.$ct-gray-650;

    font-size: common.$fz-16;
  }

  &__loaderTable {
    height: 40vh;
    width: 100%;
    padding-right: 20px;
  }

  &__loaderTableInput {
    height: 40px;
    width: 100%;
    padding-right: 20px;
    margin-bottom: 24px;
  }
}