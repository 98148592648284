@use "../../styles/common";

.form {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    min-height: 100%;

    &__checkbox:not(:last-child) {
        margin-bottom: 16px;
    }

    &__section {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    &__btn {
        margin: auto 0 15px;
        font-size: common.$fz-16;
        padding: 7px 12px;
    }

    &__textarea textarea {
        min-height: 200px;
        max-height: 500px;
    }
}
