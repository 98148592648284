@use "../../styles/common";

$operator-status-item-font-size-base: common.$menu-item-title-font-size;
$operator-status-default-color: common.$body-color;

.operatorStatus {
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    color: common.$white;
    font-size: $operator-status-item-font-size-base;
    white-space: nowrap;
    overflow: hidden;
    @include common.transition();
    background-color: $operator-status-default-color;

  &__title {
    font-size: common.$fz-12;

    &_lg {
      font-size: common.$fz-16;
    }
  }

    &__sideElement {
    }
}
