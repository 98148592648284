@use "../../styles/common";

.articleSurveyForm {
  @include common.page-layout-sidebar;
  @include common.sidebar-form;

  &__infoMessage {
    color: common.$text-muted;
  }

  &__infoMessageIcon {
    margin-right: .5rem;
  }

  &__field {
    margin-bottom: 1rem;
  }
}
