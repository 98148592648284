@use "../../../../../styles/common";

$width: 350px;

.widget {
  margin-bottom: 36px;
  width: $width;

  &__card {
    cursor: initial;
  }

  &__controls {
    display: flex;
  }

  &__header {
    background-color: var(--primary);
    color: common.$white;
    display: flex;
    justify-content: space-between;
  }

  &__settingsIcon {
    margin-left: 15px;
    color: common.$white;
    padding: 0;
  }

  &__line {
    margin-top: 16px;
    color: common.$knowledge-base-main-text;

    &_json {
      color: var(--primary);
      word-wrap: break-word;
      white-space: break-spaces;
    }
  }

  &__popover {
    &:global(.popover) {
      @include common.content-editor-popover-form;
      top: -42px !important;
    }
  }
}