@use "../../styles/common";

.copyInput {
    &:global(.btn) {
        border-right: none;
    }

    &__control {
        &:global(.btn-light) {
            align-self: center;
            background: none;
            border: none;
            color: common.$ct-gray-600;

            &:hover {
                background: none;
                border: none;
            }
        }
    }
}