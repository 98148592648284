@import "../../../../styles/common";

.rest-agent-form {
  &__radio-buttons-short {
    padding-bottom: 12px;

    .radio-input {
      margin-right: 54px;
    }
  }

  .custom-checkbox {
    margin-bottom: 1rem;
  }
}

.extract-parameter-settings {
  &:not(:last-child) {
    @include section-border;
    margin-bottom: 8px;
  }

  &__header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close {
      @include no-outline-focus();
      font-size: 2.1 * $font-size-base;
      font-weight: 400;
    }
  }

  &__title {
    margin-top: 8px;
  }

  .checkbox-input {
    margin-bottom: 1rem;
  }
}
