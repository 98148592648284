@import "../../styles/common";

.score {
    &__value {
        color: $ct-gray-650;

        &_filled {
            color: $ct-yellow-1
        }
    }
}