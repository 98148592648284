@import "../../styles/common";

.calendar-datepicker {
    .react-datepicker {
        &__header {
            background-color: transparent;
            border: none;
        }

        &__day-name {
            color: $ct-gray-650;
            text-transform: capitalize;
        }

        &__current-month {
            font-weight: normal;
            padding-bottom: 10px;
            text-transform: capitalize;
        }

        &__navigation:focus {
            outline: none;
        }

        &__month-container {
            + .react-datepicker__month-container {
                margin-left: 10px;
            }
        }

        &__day {
            &--outside-month {
                color: $ct-gray-650;
            }

            &--selected,
            &--in-range,
            &--keyboard-selected {
                background-color: var(--primary);

                &:hover {
                    background-color: var(--primary);
                }
            }

            &--in-selecting-range {
                background-color: $ct-primary-transparent-25;

                &:hover {
                    background-color: var(--primary);
                }
            }
        }

        &__navigation {
            border: 1px solid transparent;
            width: 8px;
            height: 8px;
            border-top-color: $ct-black-50;
            border-right-color: $ct-black-50;
            top: 15px;

            &--next {
                transform: rotate(45deg);

                &:hover {
                    border-left-color: transparent;
                }
            }

            &--previous {
                transform: rotate(-135deg);

                &:hover {
                    border-right-color: $ct-black-50;
                }
            }
        }

        &-time__input[type="time"] {
            &:focus {
                outline: none;
            }

            &::-webkit-datetime-edit-hour-field:focus,
            &::-webkit-datetime-edit-minute-field:focus {
                background-color: var(--primary);
            }
        }
    }
}
