@import "../../styles/common";

.frame {
    border: none;
    box-shadow: $page-box-shadow;
    &__loading {
        padding: 20px;
        width: 100%;
        height: $height-without-header;
    }
}

.frame-loader {
    display: flex;

    &__main {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0 20px;

        &-header {
            flex-grow: 1;
            margin-bottom: 20px;
        }

        &-content {
            flex-grow: 13;
        }

        @media (max-width: 800px) {
            margin: 0;
        }
    }

    &__aside {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        height: 100%;

        &-header {
            flex-grow: 1;
            margin-bottom: 20px;
        }

        &-content {
            flex-grow: 5;
        }

        @media (max-width: 800px) {
            display: none;
        }
    }
}