@import "../../styles/common";

.articleCommentThreadView {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  $self: &;
  &__replies {
    display: flex;
    flex-direction: column;
  }
  &__replyButton {
    padding-left: 0;
  }
  &_loader {
    #{$self}__inputContainer {
      width: 20%;
      height: 16px;
    }
  }
}