@use "../../styles/common";

$control-color: common.$gray-600;
$selected-color: var(--primary);

.draggableItem {
  @include common.card-border;
  transition: box-shadow .3s ease;
  background-color: common.$body-bg;
  border-radius: 5px;
  padding: 12px 16px 14px;
  display: flex;
  align-items: center;
  $self: &;

  &_draggable:hover {
    box-shadow: common.$hover-box-shadow;
  }

  &_selected {
    border-color: $selected-color;
  }

  &__drag {
    color: $control-color;
    padding-right: 14px;
    font-size: 18px;
    cursor: grab;
  }

  &__content {
    flex: 1;
    min-width: 0;
  }

  &__title {
    font-weight: 500;
    @include common.scrollable-three-row-title;
  }

  &__comment {
    margin-top: 4px;
    word-break: break-word;
    @include common.scrollable-three-row-title;
  }

  &__controls {
    display: flex;
    align-items: center;
    align-self: flex-start;
  }

  &__editIcon {
    font-size: 14px;
  }

  &__deleteIcon {
    font-size: 16px;
  }

  #{$self}__editIcon, #{$self}__deleteIcon {
    padding: 0;
    margin-left: 15px;
    cursor: pointer;
  }
}
