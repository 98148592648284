@import "../../styles/common";

.global-user-form {
    @include page-layout-sidebar();

    .section {
        margin-bottom: 20px;
    }

    .user-info {
        &__section {
            margin-bottom: 16px;
            display: flex;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__name {
            width: 30%;
            margin-right: 10px;
            &::after {
                content: ":";
            }
        }
    }
}
