@use "../../styles/common";

$title-font-size: common.$ct-font-size-base * 1.14;

.queueCardBase {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 10px 0 10px;
  }
  &__title {
    font-size: $title-font-size;
  }
  &__buttons {
    padding: 6px;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;

    padding: {
      top: 20px;
      bottom: 20px;
      left: 35px;
      right: 35px;
    };

    &_new {
      display: grid;
      align-items: baseline;
      grid-template-columns: repeat(auto-fit, 135px);
      grid-auto-rows: 100px;
      gap: 20px;

      padding: {
        left: 5px;
        right: 20px;
        top: 40px;
      };
    }
  }
  &__body {
    padding: {
      left: 40px;
      right: 45px;
      top: 30px;
    };

    &_new {
      padding: {
        left: 5px;
        right: 23px;
        top: 30px;
      };
    }
  }
  &__buttonToggle {
    text-align: center;
    & > div {
      height: 20px;
    }
  }
}