@use "../../styles/common";

.articleShareForm {
  @include common.knowledge-base-create-edit-form-popover();

  &__sharingLink {
    margin-bottom: 14px;
  }

  &__changesInheritance {
    margin: 0 0 20px;
    padding: 0 30px 0 0;
  }

  &__isLocked {
    margin: 0 0 20px;
  }

  &__isLocked {
    background: #f7f7f7;
    padding: 7px 10px;
    border-radius: 6px;
  }
}