.articleFillSlotsForm {
  margin: 20px;

  &__text {
    margin-bottom: 10px;
  }

  &__button {
    display: block;
    margin: 0 auto;
  }

  &> .form-label {
    text-align: left;
  }
}