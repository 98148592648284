.authentication-token-input {
  &__copy-input {
    padding-top: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__info-icon {
    position: absolute;
    top: 20px;
    right: -14px;
  }
}
