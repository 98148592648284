@import "../../styles/common";

$side-column-padding: 17px;

.settings-table {
    &__sort {
        margin-left: 8px;
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    &__btn-add {
        flex: 0 0 auto;
        margin-left: 24px;
    }

    &__body {
        table {
            table-layout: fixed;
            width: 100%;
            color: $ct-black-50;
            border-collapse: separate;
            border-spacing: 0 10px;

            thead th {
                font-weight: inherit;
            }
        }

        thead th, tbody td {
            text-align: left;
            height: 50px;
            padding: {
                left: $side-column-padding;
                right: $side-column-padding;
            }

            &:last-child {
                text-align: right;
            }
        }

        tbody td {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        tbody tr:hover {
            @include transition-all();
            cursor: pointer;
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
        }
    }
    
    &__pagination {
        display: flex;
        justify-content: flex-end;
    }
}