@use "../../styles/common";

$language-selector-item-font-size: common.$ct-font-size-base * 1.28;
$language-border-radius: 7px;
$initial-z-index: 7000;

.languageContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 40px;
    &__item {
        position: absolute;
        top: 0;
        right: 0;
        z-index: $initial-z-index;
        border-radius: $language-border-radius;
        background-color: common.$body-bg;
        transition: all .3s ease-in-out;
        font-size: $language-selector-item-font-size;
        user-select: none;
        padding: {
            top: 5px;
            bottom: 5px;
            right: 3px;
            left: 3px;
        };
        cursor: pointer;
        &:hover {
            color: var(--primary);
        }
        &_selected {
            order: 1;
            z-index: $initial-z-index + 10;
        }
        &_opened {
            position: relative;
            margin-left: 10px;
        }
    }
}