@import "../../styles/common";

.categories-loader {

  &__items {
    margin-bottom: 15px;
  }

  &__item {
    width: 250px;
    height: 20px;
    margin: 15px 0;
  }
}