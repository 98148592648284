@import "../../styles/common";

.slot {
    &__section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__content {
        cursor: pointer;
        min-height: 40px;
        word-break: break-word;
        display: flex;
        align-items: center;

        &_disabled {
            pointer-events: none;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
        }

        &_readonly {
            overflow-y: auto;
            align-content: center;
        }

        &_link {
            overflow-y: hidden;
        }
    }

    &__input {
        position: relative;
        width: 100%;

        &_increased > textarea {
            resize: none;
            min-height: 75px;
            overflow-y: auto;
            word-break: break-word;
        }
    }

    &__title {
        flex: 0 0 30%;
        word-break: break-word;

        &::after {
            content: ":";
        }
    }
}

.copy {
    &_removedOnEdit {
        display: none;
    }

    &_hidden {
        visibility: hidden;
    }
    
    &__button {
        display: flex;
        align-items: center;
        margin-left: 8px;
        width: 40px;
        height: 40px;
        background: none;
        color: $ct-gray-600;
        border: 1px solid $ct-gray-300;

        &:hover {
            background: none;
        }
    }
}

.actions {
    display: flex;
    position: relative;
    width: 48px;
    height: 40px;

    &_hidden {
        display: none;
    }

    &__button {
        background: none;
        border: none;

        svg {
            margin-left: 3px;
        }

        path {
            fill: $ct-gray-600;
        }

        &:hover {
            path {
                fill: var(--primary);
            }
        }
    }
}
