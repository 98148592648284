.queueAutoGreeting {
  &__toggle {
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    &__label {
      margin-left: 13px;
      margin-bottom: 0;
    }
  }

  &__textarea > textarea {
    resize: none;
    min-height: 90px;
  }
}