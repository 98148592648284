@import "../../styles/common";

$task-board-toggle-hover: $ct-gray-200;
$task-board-vertical-spacing: 15px;
$task-board-horizontal-spacing: 20px;

@mixin task-card-order {
    :global(.task-card) {
        margin-right: 15px;
    }
}

.taskBoard {
    width: 100%;
    padding: {
        top: $task-board-vertical-spacing;
        bottom: $task-board-vertical-spacing;
    };
    &__head {
        width: 100%;
        min-height: $board-min-height - ($task-board-vertical-spacing * 2);
        display: flex;
        flex-direction: row;
        margin: {
            left: $task-board-horizontal-spacing;
            right: $task-board-horizontal-spacing;
        };
        @include task-card-order;
    }
    &__tail {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-right: 50px;
        margin: {
            left: $task-board-horizontal-spacing;
            right: $task-board-horizontal-spacing;
        };
        @include task-card-order;
        :global(.task-card) {
            margin-top: 15px;
        }
    }
    &__no-tasks {
        @include text-muted;
        display: flex;
        align-items: center;
    }
}

.taskBoardToggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: {
        left: 15px;
        right: 15px;
    };
    transition: $transition-base;
    border-radius: $border-radius;
    cursor: pointer;
    &:hover {
        background-color: $task-board-toggle-hover;
    }
    &__text {
        white-space: nowrap;
        font: {
            weight: 500;
            size: 16px;
        }
    }
    &__count {
        color: $text-muted
    }
}