@import "../../../styles/common";

.article-filter-popover {
  z-index: 1060;
  width: 450px;
  max-width: 450px;

  &__open-filters-icon {
    margin: 0;
  }

  .popover-body {
    padding: 20px;
  }
}