@import "../../styles/common";

.scalable-list {
  &__toggle {
    color: $text-muted;
    cursor: pointer;
    @include transition();
    &:hover {
      color: var(--primary)
    }
  }
}