.sidebar {
    
    &__header {
        padding: 0;
    }

    &__intermediate {
        display: flex;
        align-items: center;
        padding: 40px 16px 12px;
        gap: 16px;
    }

    &__section {
        padding: 12px 16px;
    }
}

.content {
    padding: 40px 20px;
}
