@use "../../styles/common";

.hugeLogo {
  & > img {
    max-height: 50px;

  }

  &_clickable {
    cursor: pointer;
  }
}