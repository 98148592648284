@use "../../styles/common" as common;

@mixin icon {
    @include common.transition-all();
    cursor: pointer;
    color: common.$sub-text-color;

    &:hover {
        color: var(--primary);
    }
}

.fileUploader {
    $self: &;
    display: flex;
    position: relative;
    min-width: 150px;
    font-size: common.$ct-font-size-base;

    &__controls {
        display: flex;
        align-items: start;
        gap: 10px;
        margin-left: auto;
    }

    &__fileLink {
        display: none;
    }

    &__icon {
        @include icon;

        &_left {
            margin-right: 9px;
        }

        &_disabled:hover {
            color: common.$sub-text-color;
            cursor: default;
        }
    }
}
