.queuesTaskTableLoader {
  &__loader {
    padding: 20px;
  }

  &__loaderTableInput {
    height: 40px;
    width: 100%;
    padding-right: 20px;
    margin-bottom: 24px;
  }

  &__loaderTable {
    height: 40vh;
    width: 100%;
    padding-right: 20px;
  }
}