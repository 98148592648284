@import "../../styles/common";

$operator-size: 16%;
$stats-size: 8%;
$tasks-size: 100% - ($operator-size + $stats-size);

.queue-operator-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    &__operator {
        display: flex;
        flex: 1 1 $operator-size;
        padding: 15px 5px;
        border-radius: $border-radius;
        gap: 5px;

        &_selected {
            box-shadow: $hover-box-shadow;
        }
    }
    &__settings {
        align-self: flex-start;
    }
    &__settings-btn {
        padding-top: 0px;
    }
    &__stats {
        width: $stats-size;
        flex: 1 1 $stats-size;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: {
            left: 10px;
            right: 10px;
        };
    }
    &__stats-count {
        font: {
            size: 16px;
            weight: 500;
        }
        margin-right: 5px;
    }
    &__stats-field-name {
        @include text-muted;
    }
    &__tasks {
        width: $tasks-size;
        flex: 2 0 $tasks-size;
    }
}