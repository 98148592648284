@use "../../styles/common";

$notification-badge-bg-color: var(--primary);
$notification-badge-size: 16px;
$notification-badge-font-size: 0.78 * common.$font-size-base;

.iconButton {
  position: relative;
  &:global(.btn-primary:focus) {
    box-shadow: none;
    background-color: $notification-badge-bg-color;
    border-color:  $notification-badge-bg-color;
  }
  &__icon {
    margin-right: 10px;
  }

  &__notificationBadge {
    position: absolute;
    top: -5px;
    right: -5px;
    width: $notification-badge-size;
    height: $notification-badge-size;
    font-size: $notification-badge-font-size;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $notification-badge-bg-color;
    color: common.$white;
  }
}