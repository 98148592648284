@use "../../styles/common";

$title-font-size: common.$ct-font-size-base;

.queueInfoItem {
    min-width: 250px;
    margin-right: 30px;
    margin-bottom: 30px;

    border-radius: 5px;
    transition: all 0.3s ease-in-out;

    &_small {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 20px;
        height: 100%;
        border-radius: 16px;
        padding-bottom: 0;
        min-width: 100px;
        margin-bottom: 5px;
    }

    &_selectable {
        &:hover {
            box-shadow: common.$hover-box-shadow;
            color: #727272;
            cursor: pointer;
            user-select: none;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        padding-bottom: 5px;
    }

    &__title {
        font-size: $title-font-size;

        &_small {
            width: 69px;
        }
    }

    &__target {
        font-size: $title-font-size;
        margin-left: 8px;
        padding: 1px 4px;
        color: common.$ct-gray-650;
        border: 1px solid;
        border-radius: common.$border-radius;
    }

    &__body {
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0 5px;
    }

    &__value {
        font-size: common.$ct-font-size-base * 1.7;

        &_warning {
            color: common.$ct-red-1;
        }
    }

    &__period {
        white-space: pre;
        font-size: common.$ct-font-size-base * 1.3;
        font-weight: 500;
    }

    &__icon {
        color: var(--primary);
        margin-right: 8px;
        &_error {
            color: common.$danger;
        }
    }

    &__loader {
        height: 3rem;
        width: 100px;
    }

    &__description {
        color: common.$sub-text-color;
    }
}