@use "../../../../../styles/common" as common;

.extraSetting {
  @include common.section-border;
  display: flex;
  flex-direction: column;
  padding: 12px 0 12px;
  margin-bottom: 8px;

  &__header {
    display: flex;
    align-items: center;
    padding: 0 0 10px;
  }

  &__title {
    flex: 1;
    margin-bottom: 10px;

    &__headerTitle {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }

  &__deleteIcon {
    padding: {
      left: common.$input-padding-x;
      right: common.$input-padding-x;
    }
    cursor: pointer;
  }
}
