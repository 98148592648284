@use '../../../styles/common';

.dropdown {
    margin-top: 40px;
    padding: 17px 0;
    display: flex;
    flex-flow: column;
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 5px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    background-color: common.$white;

    &__list {
        display: flex;
        flex-direction: column;
        gap: 4px;
        max-height: 280px;
        overflow: auto;
    }

    &__empty {
        color: common.$ct-gray-600;
        text-align: center;
    }
}