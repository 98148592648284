@import "../../styles/common";

.article-edit-form {

  &__input {
    @include no-outline-focus();
    flex: 1;
    padding: 0;
    margin: 0;
    font-size: $knowledge-base-article-title-font-size;
    font-weight: bold;
    color: $ct-gray-850;
    resize: none;
    border: none;
  }

  &__title {
    @include no-outline-focus();
  }

  &__text {
    font-size: $knowledge-base-article-text-font-size;
  }

  &__add-parameter-btn {
    svg {
      font-size: 20px;
      vertical-align: -0.30em;
      margin-left: 7px;
    }
  }
}