@use "../../styles/common" as common;

$codeBlockFontSize: common.$ct-font-size-base;
$codeBlockRemoveButtonSize: 0.9 * common.$ct-font-size-base;
$codeBlockBackgroundColor: common.$ct-gray-150;

.codeBlockInput {
  position: relative;
  padding: 20px;
  font-size: $codeBlockFontSize;
  box-sizing: border-box;
  font-family: "Dank Mono", "Fira Code", monospace;
  margin: 15px 0;
  background-color: $codeBlockBackgroundColor;
  border-radius: 15px;

  :focus-visible {
    outline: none;
  }

  &__removeButton {
    display: flex;
    padding: 3px;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 3px;
    color: common.$knowledge-base-main-text;
    font-size: $codeBlockRemoveButtonSize;

    :hover > & {
      opacity: 0.3;
    }

    &:hover {
      opacity: 1;
      color: common.$knowledge-base-main-text;
    }
  }
}

