@use "../../../../../styles/common";

.messageBasicLoader {
    display: flex;
    justify-content: flex-start;
    $self: &;

    &_out {
        justify-content: flex-end;
        #{$self}__body {
            flex-direction: row-reverse;
        }
        #{$self}__picture {
            margin-left: 10px;
        }
    }

    &__body {
        display: flex;
    }

    &__message {
        width: 180px;
        height: 53px;
    }

    &__picture {
        height: 45px;
        width: 45px;
        margin-right: 10px;
        :global(.skeleton) {
            border-radius: 50%;
        }
    }
}
