@import "../../styles/common";

$article-user-view-card-icon-font-size: $ct-font-size-base * 1.3;

.article-user-view-card {
  display: flex;
  align-items: center;
  padding: 5px;

  &__confirms {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  &__icon {
    color: $knowledge-base-sub-text;
    font-size: $article-user-view-card-icon-font-size;

    &_active {
      color: $knowledge-base-main-text;
    }

    &:first-child {
      margin-right: 10px;
    }
  }
}