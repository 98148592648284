@use "../../styles/common";

.logo {
    & > img {
        max-height: 50px;
        margin-right: 20px;
    }

    &_clickable {
        cursor: pointer;
    }
}
