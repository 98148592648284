@use "../../../styles/common" as common;

.ArticleHistoryItem {
  width: 100%;
  height: 100px;
  padding: 20px;
  border-top: 1px common.$ct-gray-300 solid;
  cursor: pointer;
  @include common.transition-all();
  &_latest {
    height: 125px;
  }
  &__date {
    @include common.flex-row-space-between;
    font-size: 1rem;
    color: common.$ct-black-50;
  }

  &__compare {
    font-size: .875rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: common.$ct-gray-600;
    @include common.transition-all();

    &_hidden {
      display: none;
    }

    &:hover {
      cursor: pointer;
      color: var(--primary)
    }
  }

  &__current {
    font-size: .875rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: common.$ct-gray-600;
  }

  &__footer {
    @include common.flex-row-space-between;
    padding-top: 10px;
  }

  &:hover {
    background-color: common.$ct-gray-175;
  }

  &_current {
    background-color: common.$ct-gray-50;
  }
}

.accordion {
  &__title {
    padding-left: 20px;
  }

  &__toggle {
    padding-right: 20px;
  }
}