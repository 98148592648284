@import "../../styles/common";

$h1-font-size: $ct-font-size-base * 2;
$h2-font-size: $ct-font-size-base * 1.71;
$h3-font-size: $ct-font-size-base * 1.43;

.article-content-editor {
  width: 100%;

  h1 {
    font-size: $h1-font-size;
    margin-top: 1.8em!important;
  }

  h2 {
    font-size: $h2-font-size;
    margin-top: 1.4em!important;
  }

  h3 {
    font-size: $h3-font-size;
    margin-top: 1em!important;
  }

  li:not(:first-child) {
    margin-top: 0.5em
  }
}
