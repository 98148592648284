$height: 21px;
$width: 100px;

.infoFieldLoader {
    &__name {
        width: $width;
        height: $height;

        margin-bottom: 12px;
    }

    &__value {
        width: $width * 2;
        height: $height;
    }
}