@use "../../../../styles/common";
@use "../../variables.scss";

.item {
    padding: 10px 16px;
    cursor: pointer;

    &_active,
    &:hover {
        background-color: common.$ct-gray-175;
    }

    &__title {
        margin-bottom: 8px;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__status {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    &__indicator {
        width: 8px;
        height: 8px;
        border-radius: 50%;

        @each $status, $color in variables.$mass-mailing-indicators {
            &_#{$status} {
                background-color: #{$color};
            }
        }
    }
}
