@use "../../../../styles/common";

.articleImportFiles {
  &_empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    padding: 35px 0;
    color: common.$ct-gray-600;
    font-size: common.$fz-16;
  }

  &__filesList {
    display: flex;
    flex-direction: column;
    gap: 8px;

    padding: 20px 0;
  }

  &__input {
    display: none;
  }

  &__fileSelect {
    outline: none;
    border: none;
    background: none;
    color: var(--primary);
  }

  &__fileSelectMore {
    display: flex;
    gap: 10px;
    align-items: center;

    outline: none;
    border: none;
    background: none;
    color: var(--primary);
  }
}