@import "../../styles/common";

$article-comments-view-vertical-spacing: 20px;

.articleCommentsView {
  @include article-section;
  margin-top: $article-comments-view-vertical-spacing;
  $self: &;

  &__inputContainer {
    margin-bottom: $article-comments-view-vertical-spacing;
  }

  &_loader {
    #{$self}__inputContainer .skeleton {
      width: 25%;
      height: 20px;
    }
  }

  &__enableInputMode {

  }

  &_expanded {
    @include article-section-expanded;
  }
}