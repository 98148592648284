@import "../../../../styles/common";

.common-node-header {
  text-align: center;
  padding: 3px 0 3px 30px;

  .btn-close {
    @include transition-all();
    padding: 0 22px 0 0;
    float: right;
    opacity: 0;
    pointer-events: none;

    svg {
      font-size: 1.1rem;
    }
  }

  &__icon {
    margin-right: 10px;
  }
}