@use "../../styles/common" as common;

.pagination {
  display: flex;
  align-items: center;

  &__pages {
    padding-right: 10px;
  }

  &__pageArrow {
    &_disabled {
      color: common.$text-muted;
    }
  }
}