@use "../../styles/common";

.select {
    padding: 0;

    &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 180px;
        padding-left: 4px;
    }

    &__selected {
        @include common.text-truncate();
        padding-right: 10px;
        line-height: 30px;
        font-size: common.$fz-14;
    }

    &__arrow {
        font-size: common.$fz-20;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__menu {
        @include common.mini-scrollbar();

        max-height: 250px;
        overflow-y: auto;
    }
}
