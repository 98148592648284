@import "../../styles/common";

.current-branch {
  max-width: 30%;
  color: $knowledge-base-main-text;

  &:hover {
    color: $knowledge-base-primary-text;
  }

  @include article-action();

  @media (max-width: 1530px) {
    max-width: 100%;
  }

  &__parent-title {
    @include no-wrap-text();
  }
}

.current-branch-error.btn {
  color: $danger;

  &:hover {
    color: $danger;
  }
}
