@import "../../styles/common";

.operator-queues-form {
    @include page-layout-sidebar();
    &__queue {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    &__queues {
        margin-bottom: 16px;
    }
}