@use "../../../../styles/common";

.loader {
    padding: 0 15px;

    &__item {
        border-bottom: 1px solid common.$ct-gray-300;
        padding: 15px 0;
        width: 100%;
        height: 100px;
    }
}
