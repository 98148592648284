@use "../../styles/common";

$width: 400px;

.projectWizardError {
  margin: 0 auto;
  width: $width;

  &__image {
    margin-bottom: 50px;
  }

  &__btn {
    width: $width;
  }
}