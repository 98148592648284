.articleAudio {
    border-radius: 10px;
    background: #f2f2f2;
    min-width: 400px;
    max-width: 800px;
    padding: 0 32px;
    height: 60px;
    margin-bottom: 20px;
    position: relative;

    display: flex;
    align-items: center;

    &__playBtnContainer {
        cursor: pointer;
        width: 44px;
        height: 44px;
        background: #e0e0e0;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__controlBtn {
        width: 28px;
        height: 28px;
        padding: 0;
        margin: 0;
        border: none;
        outline: none;
    }

    &__cogBtn {
        margin-left: 12px;
    }

    &__playIcon {
        margin-left: 5px;
    }

    &__duration {
        font-size: 1.125rem;
        color: #333;
        margin-left: 6px;
        user-select: none;
    }

    &__lineContainer {
        flex-grow: 1;
        margin: 0 24px;
        padding: 5px 0;
        cursor: pointer;
    }

    &__line {
        height: 6px;
        border-radius: 8px;
        background: rgba(162, 162, 162, 0.5);
        position: relative;
    }

    &__thumb {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #333333;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    &__progress {
        height: 6px;
        border-radius: 8px;
        background-color: #333333;
        width: 0;
    }

    &__volumeIcon {
        width: 28px;
        height: 28px;
        cursor: pointer;
    }

    &__cogIcon {
        cursor: pointer;
    }

    &__fallbackContainer {
        margin: 20px 0;
    }

    &__settingsContainer {
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
        background: #333;
        padding: 8px 12px;
        border-radius: 5px;

        svg {
            cursor: pointer;

            &:last-child {
                margin-left: 13px;
            }
        }
    }
}
