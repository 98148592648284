@import "../../styles/common";

$sample-questions-side-padding: 20px;

.sample-questions {
  padding: 10px 0;

  &__header {
    padding: 0 $sample-questions-side-padding;
  }

  &__add-sample {
    display: flex;
    padding-bottom: $sample-questions-side-padding;
  }

  &__textarea {
    @include mini-scrollbar;
    @include enable-focus-within;
    @include transition;
    border-color: $input-border-color;
    flex: 1;
    border-radius: 5px;
    resize: none;
    padding: $input-padding-y $input-padding-x !important;
    height: calc(#{$input-height} - 2px);
    margin-right: 16px;
  }

  &__add-btn {
    align-self: flex-start;
  }

  &__toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__buttons {
    padding-top: 20px;
  }

  &__select {
    display: flex;
    align-items: center;
    padding-left: 5px;
  }

  &__filter {
    width: 110px;
  }

  &__filter-text {
    @include text-truncate();
    padding-right:10px;
  }

  &__filter-icon {
    font-size: 20px;
    line-height: 20px;
    float: right;
  }

  &__list {
    padding: 20px $sample-questions-side-padding 0;
  }

  &__list-item {
    margin-top: 16px;

    &:first-of-type {
      margin-top: 0;
    }
  }
}