@import "../../../../styles/common";

$form-node-item-height: 50px;
$form-node-add-line-length: 23px;

$form-node-bg-color: $ct-editor-3;
$form-node-add-btn-color: $ct-gray-600;

.react-flow {
  .form-node {
    @include card-border();
    width: $form-node-width;
    border-radius: 5px;
    transition: $transition-base;
    background-color: $body-bg;

    &_selected {
      .btn-close {
        opacity: 1;
        pointer-events: initial;
      }

      .form-node {
        &__remove-icon, &__drag-icon, &__checkbox {
          opacity: 1;
          pointer-events: initial;
        }
      }
    }

    &__header {
      background-color: $form-node-bg-color;
      padding: 3px 0 3px 30px;
    }

    &__content {
      padding-top: 10px;
    }

    &__item-container {
      height: $form-node-item-height;
    }

    &__add-btn {
      background-color: $body-bg;
      color: $form-node-add-btn-color;
      width: 100%;
      padding: 12px 23px;
      border: none;
      transition: $transition-fade;
      border-radius: 0 0 5px 5px;
    }

    &__add-icon {
      margin-right: 10px;
    }

    &__checkbox {
      pointer-events: none;

      & > .custom-control-input:checked ~ .custom-control-label::before {
        background-color: var(--editor);
        border-color: var(--editor);
      }
    }
  }
}



:global(.custom-control-input:checked ~ .custom-control-label::before) {
  color: $ct-editor-3;
}

.form-node {
  &_not-connectable {
    .form-node__item-container:last-child {
      .form-node__item {
        border-bottom: none;
        border-radius: 0 0 5px 5px;
      }
    }
  }

  &__item {
    @include section-border;

    text-align: center;
    padding: 0 23px;
    background-color: $body-bg;
    height: $form-node-item-height;
    display: flex;
    align-items: center;

    &_dragging {
      @include card-border;

      .form-node {
        &__remove-icon, &__drag-icon, &__checkbox {
          opacity: 1;
        }
      }
    }
  }

  &__drag-icon {
    @include no-outline-focus();

    float: left;
    padding: 0 8px;
    color: $scenario-node-controls-color;
    font-size: 1rem;
    cursor: grab;
    margin-right: 16px;

    opacity: 0;
    pointer-events: none;
  }

  &__remove-icon {
    @include transition;
    color: $scenario-node-controls-color;
    opacity: 0;
    pointer-events: none;
    font-size: 18px;
    cursor: pointer;
  }

  &__title {
    @include text-truncate;
    padding-right: 5px;
    width: 200px;
  }

  &__condition-list {
    &:before {
      height: calc(100% - 32px);
    }

    &_connectable {
      &:before {
        height: calc(100% - 23px);
      }
    }

    .condition-node-list {
      &__add-btn:before {
        top: -$form-node-add-line-length;
        height: $form-node-add-line-length;
      }
    }
  }
}