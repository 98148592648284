@use "../../styles/common";

.articlePublishForm {
  @include common.knowledge-base-create-edit-form-popover();

  &__group {
    padding-top: 8px;
  }

  &__subgroup {
    padding-left: 25px;
  }

  &__footer {
    padding-top: 25px;
  }

  :global(.custom-control-label) {
    font-size: common.$fz-14;
  }
}