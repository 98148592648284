@use "../../styles/common";

.indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 24px;
    height: 100%;

    &__icon {
        cursor: pointer;
        color: common.$body-color;
        font-size: common.$fz-18;
    }
}
