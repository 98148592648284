@use "../../styles/common";

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    transition: all 0.15s;
    background-color: var(--primary);
    padding: 0 20px;
    height: var(--header-height);
    color: white;

    &__side {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        height: inherit;

        &:first-child {
            @media (min-width: 1440px) {
                justify-content: start;
                min-width: 390px;
            }
        }
    }

    &__newsline {
        flex-grow: 1;
        margin-right: auto;
        max-width: 750px;
    }

    &__newsline ~ &__controlArea {
        margin-left: 24px;
    }

    &__controlArea {
        position: relative;
    }

    &__counter {
        position: absolute;
        top: 2px;
        left: -5px;
        background-color: white;
        width: 16px;
        height: 16px;
        color: var(--primary);
    }
  &__side {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 17px;
    height: inherit;
  }

  &__middle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &__userProfile {
    display: flex;
    height: inherit;
  }

  &__dialogsCounter {
    position: absolute;
    top: 2px;
    left: -5px;
    background-color: white;
    width: 16px;
    height: 16px;
    color: var(--primary);
  }

  &__errorText {
    margin-top: 0;
  }
}
