@import "../../styles/common";

.login-form {
    @include public-form;

    &__forgot-password {
        text-align: end;
        margin-bottom: 1rem;

        & > a {
            color: var(--link);
        }
    }
}