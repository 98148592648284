@import "../../styles/common";

.user-profile-form {
    .form-label {
        text-align: right;
        color: $ct-gray-650;
    }

    &__submit {
        margin-top: 40px;
    }

    .section {
        &__header {
            @include section-border;
            margin-bottom: 30px;
        }

        &__body {
            padding: 0 30px;

            &-text {
                margin-bottom: 16px;
            }
        }
    }

    .btn-submit {
        width: 120px;
    }

    .avatar-input {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .btn-link {
        &__icon {
            margin-right: 5px;
        }
    }
}

