@import "../../styles/common";

.channel-type-form {
  @include page-layout-sidebar();
  $self: &;

  &__content {
    padding: 10px 22px 0;

    #{$self}__card {
      padding: 24px;
    }

    #{$self}__icon {
      height: 100%;
      width: fit-content;
      font-size: 2.3rem;
    }
  }

  .msbot-icon, .teams-icon {
    margin-top: -5px;
    height: 50px;
    width: 50px;
  }
}
