@import "../../styles/common";

.article {
    &__prepended {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 40px;
        margin: 20px 0
    }

    &__parameters {
        @include article-section;
        margin-bottom: 40px;
    }

    &__body {
        @include article-section;
        display: flex;
        font-size: $knowledge-base-article-text-font-size;
        word-break: break-word;
    }

    &_expanded {
        @include article-section-expanded;
    }
}

.scrollButton {
    position: absolute;
    bottom: 49px;
    right: 124px;
    z-index: 1;

    &_expanded {
        right: 49px;
        bottom: 59px;
    }
}