@import "../../styles/common";

.choose-conditions-form {
  @include page-layout-sidebar();

  &__content {
    padding: 10px 22px 0;
  }

  &__subfield {
    @include subfield;
  }

  &__row {
    align-items: center;
  }

  &__slider {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 20px;
  }

  .validatable-input-with-control {
    .btn, .dropdown-menu {
      min-width: 4.6rem;
    }
  }
}