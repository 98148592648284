@import "../../../../../styles/common";

.advancedSettingsInput {
	textarea {
		min-height: 400px;
	}
}

.isModuleSettingsWrapper {
	display: flex
}