@import "../../styles/common";

.item {
    display: flex;
    align-items: center;
    gap: 8px;
    @include interactive-menu-item;
    padding: 8px 16px;
    border-radius: 0;
    @include transition();
    user-select: none;

    &:hover {
        background-color: $sidebar-hover-color;
    }

    &_separated {
        border-top: 1px solid $ct-gray-300;
    }

    &_danger {
        color: $ct-red-4;
    }

    &_disabled {
        color: $text-muted;
    }

    .item__icon {
        width: 18px;
        height: 18px;
        margin: 1px 3px;
    }

    &__toggle_loading {
        animation: rotate-shadow 0.5s linear infinite;
    }
}

@keyframes rotate-shadow {
    0% {
        filter: drop-shadow(0 2px 0px var(--primary));
    }
    25% {
        filter: drop-shadow(-1px 0 0px var(--primary));
    }
    50% {
        filter: drop-shadow(0 -1px 0px var(--primary));
    }
    75% {
        filter: drop-shadow(2px 0 0px var(--primary));
    }
    100% {
        filter: drop-shadow(0 2px 0px var(--primary));
    }
}
