@use "../../styles/common";

.iconControl {
  @include common.button-icon;
  @include common.transition();
  outline: none;
  cursor: pointer;

  &_disabled {
    pointer-events: none;
  }
}