@import "../../styles/common";

$margin-bottom: 15px;

.wizard-project-upload {
    &__select {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: $margin-bottom;
    }

    &__select-btn {
        flex-shrink: 0;
        margin: 0 auto;
    }

    &__file-name {
        flex-grow: 0;
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
    }

    &__upload {
        &_hidden {
            visibility: hidden;
        }
        margin-bottom: $margin-bottom;
    }


    &__progress {
        &_hidden {
            visibility: hidden;
        }
        margin-bottom: $margin-bottom;
    }

    &__file-input {
        display: none;
    }

    &__feedback {
        display: block;
    }
}