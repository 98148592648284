@use '../../styles/common' as common;

.creatableTagSelect {
    margin: 0;

    :global(.custom-select__group) {
        padding: 0;
    }

    :global(.custom-select__option) {
        padding: 8px 30px;
    }

    &__groupHeading {
        display: flex;
        padding: 8px 12px;
        margin: 0;
        cursor: pointer;
        align-items: center;
        font: inherit;
        line-height: 1;

        :global(.custom-select__group-heading) {
            padding: 0;
            margin: 0;
            text-transform: unset;
            color: common.$ct-black-50;
            font: inherit;
        }

        &__label {
            margin-left: 10px;
        }
    }

    &__options {
        &_collapsed {
            display: none;
        }
    }

    &__option {
        &:not(:global(.custom-select__single-value) *) {
            margin-left: 10px;
        }

        &_noGroup {
            &:not(:global(.custom-select__single-value) *) {
                margin-left: -7px;
            }
        }
    }

    &__tags {
        margin-bottom: 6px;
    }

    &__tag {
        @include common.selectedTag;
    }

    &__tagInput {
        :global(.custom-select__indicators) {
            display: none;
        }

        &_withArrow {
            :global(.custom-select__indicators) {
                display: flex;
            }

            :global(.custom-select__indicator) {
                transition: transform 130ms ease;
                transform: rotate(0deg);
            }

            :global(.custom-select__control--menu-is-open .custom-select__indicator) {
                transform: rotate(180deg);
            }
        }

        :global(.custom-select__single-value) {
            color: common.$ct-black-50;
        }
    }

    &__error {
        font-size: 0.75rem;
        color: common.$ct-red-1;
        margin: 4px 0 0;
    }
}
