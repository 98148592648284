.queueCardBaseLoader {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    height: 40px;
    width: 150px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;

    padding: 20px 35px 60px;
  }

  &__item {
    height: 91px;
    width: 98px;
    border-radius: 16px;
  }

  &__tableInput {
    height: 40px;
    width: 100%;
    padding-right: 20px;
    margin-bottom: 24px;
  }

  &__table {
    height: 50vh;
    width: 100%;
    padding-right: 20px;
  }
}