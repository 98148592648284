@import "../../styles/common";

$task-card-max-width: 200px;
$task-card-background-color: $ct-gray-150;
$task-card-horizontal-padding: 12px;
$task-card-client-color: $body-color;
$task-card-channel-name-size: $sub-text-size;
$task-card-channel-name-weight: 500;

$task-card-project-color: (
        "link": var(--primary),
        "disabled": $text-muted
);
@each $channel, $value in $channel-colors {
    .task-card-channel_#{$channel} {
        background-color: $value;
    }
}
@each $key, $value in $task-card-project-color {
    .task-card-client_#{$key} {
        color: $value;
    }
}
@mixin task-card-channel-name {
    font: {
        size: $task-card-channel-name-size;
        weight: $task-card-channel-name-weight;
    }
}
@mixin task-card-client-name {
    font: {

    }
}
@mixin task-card-info-date {
    color: $gray-500;
    font: {
        size: $task-card-channel-name-size;
    }
}

.task-card {
    display: flex;
    flex-direction: column;
    background-color: $task-card-background-color;
    border-radius: $border-radius;
    width: $task-card-max-width;
    cursor: pointer;
    transition: $transition-base;
    box-shadow: $small-highlight-box-shadow;
    &:hover {
        box-shadow: $hover-box-shadow;
    }
    &_unknown {
        cursor: not-allowed;
    }
    &_selected {
        box-shadow: $hover-box-shadow;
    }
    &__channel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: {
            left: $task-card-horizontal-padding;
            right: $task-card-horizontal-padding;
            top: 4px;
            bottom: 4px;
        };
        border-radius: $border-radius $border-radius 0 0;
        color: $white;
        .task-card-channel__icon {
            margin-right: 5px;
            .task-card-channel-icon__item {
                display: block;
                height: 14px;
            }
        }
        .task-card-channel__name {
            @include no-wrap-text;
            @include task-card-channel-name;
        }
    }
    &__client {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: {
            left: $task-card-horizontal-padding;
            right: $task-card-horizontal-padding;
            top: 6px;
        };
        .task-card-client__icon {
            margin-right: 5px;
            .task-card-project-icon {
                height: 12px;
            }
        }
        .task-card-client__name {
            @include task-card-client-name;
            @include no-wrap-text;
        }
    }
    &__message {
        padding: {
            left: $task-card-horizontal-padding;
            right: $task-card-horizontal-padding;
        }
        color: $gray-500;
        font-size: .85rem;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        word-wrap: break-word;
        -webkit-box-orient: vertical;
    }
    &__info {
        display: flex;
        flex-direction: row;
        padding: {
            left: $task-card-horizontal-padding;
            right: $task-card-horizontal-padding;
            top: 4px;
            bottom: 6px;
        };
        .task-card-info__date {
            @include task-card-info-date;
        }
    }
    &__tooltip {
        font: {
            size: $sub-text-size;
        }
    }
    &__loader {
        border-radius: $border-radius;
        width: $task-card-max-width;
        height: 5rem;
        margin-right: 15px;
    }
}