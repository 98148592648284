@use "../../styles/common" as common;

.modalContainer {
    &__content {
        width: 681px;
    }

    &__header {
        font-size: common.$fz-20;
        font-weight: 500;
        border-bottom: 0;
        padding: 20px 20px 0 20px;
    }

    &__footer {
        padding: 10px 20px 20px 20px;
        border-top: 0;
        gap: 5px;
    }

    &__body {
        padding: 0 20px 0 20px;
        flex: none;
    }
}