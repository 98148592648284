@use "../../styles/common";

.newsline {
    display: flex;
    align-items: center;
    gap: 24px;
    animation: fade 0.3s ease-in-out 1;
    cursor: pointer;
    padding: 0 24px;
    height: var(--header-height);

    &::before,
    &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: common.$white;
        width: 1px;
        height: 25px;
        content: "";
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }
}

.view {
    position: relative;
    width: 100%;
    height: var(--header-height);
    overflow: hidden;
}

.news {
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
}

.popover:global(.popover) {
    border-radius: 0;
    width: 100%;

    :global(.arrow) {
        display: none;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
