.knowledgeBase {
  &__catalogs {
    &_hidden {
      display: none;
    }
    &_visible {
      display: block;
    }
  }

  &__search {
    margin: 0 15px 10px;
  }
}