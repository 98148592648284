@use "../../styles/common";

$notification-container-animation-duration: .5s;

.notificationContainer {
  &__item {
    border-radius: 0;
    box-shadow: none;
  }
  &_animate {
    animation-duration: $notification-container-animation-duration;
    animation-fill-mode: both;
  }
  &_fadeInRight {
    animation-name: fadeInRight;
  }
  &_fadeOutRight {
    animation-name: fadeOutRight;
  }
}
