@use "../../../styles/common";

$message-input-vertical-spacing: 20px;
$advanced-input-height: 3.5rem;

.messageInput {
    &__main {
        padding: 12px 15px 15px 15px;
        position: relative;
        max-height: calc(100% - 135px);
        transition: height 0.5s cubic-bezier(0, 1, 0, 1)
    }

    &_maximized {
        height: calc(100vh - 220px);
        transition: height 1s ease-in-out;
        display: flex;
        flex-direction: column;

        &_withPin {
            max-height: calc(100vh - 290px);
        }
    }

    &__user {
        margin-bottom: $message-input-vertical-spacing;
    }

    &__wrapper {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        align-items: center;
    }

    &__footer {
        display: flex;
        margin-top: 5px;
        padding: 12px 10px 10px 16px;

        &_maximized {
            display: flex;
            margin-top: 65px;
            padding: 12px 15px 15px 15px;
        }
    }

    &__quickButton {
        @include common.transition-all();
        cursor: pointer;
        margin-right: 14px;

        &:hover {
            color: var(--primary);
        }

        &_active {
            color: var(--primary);
        }

        &_withRightMargin {
            margin-right: 24px;
        }

        &_withLeftMargin {
            margin-left: 20px;
        }
    }

    &__quickButtons {
        display: flex;
        flex: 1;
        align-items: center;
        color: var(--primary);
        font-size: 18px;

        &_disabled {
            cursor: not-allowed;

            & > svg {
                pointer-events: none;
            }
        }
    }

    &__fileInput {
        display: none;
    }

    &__control {
        flex-grow: 1 !important;
        height: 100% !important;
        border: none;
        resize: none;
        padding: 0 0 5px 5px;
        overflow: auto;
        &_advanced {
            & > div:first-child {
                height: 100%;
            }
        }

        &_disabled > div:first-child > div:first-child > p:first-child {
            color: #d0d0d0;
            font-size: 1rem;
        }

        &_stable {
            max-height: 265px;
            justify-content: flex-start !important;
        }
    }
    
    &__buttonGroup {
        height: 100%;
        display: flex;
        align-items: center;

        > button {
            padding: 0.4rem !important;
        }
    }

    &__submit {
        margin-left: 8px;
    }

    &__tooltip {
        text-align: left;

        p {
            margin: 0;
        }
    }

    &__tooltipContainer {
        flex: 0 0 21px;
        width: 21px;
        margin-left: 10px;
        margin-right: 16px;

        &__icon:hover {
            @include common.transition-all();

            color: black;
        }
    }

    :global(.ProseMirror) {
        word-break: break-word;

        blockquote {
            border-left: 2px solid common.$rme-quotes !important;
            margin: 0;
            padding-left: 1.5em;
            font-style: normal;
        }

        > p > a {
            color: common.$rme-link;
        }
    }
}
