@import "../../styles/common";

.spinner {
    display: inline-block;
    $self: &;
    &_indeterminate {
        animation: CircularRotate 1.4s linear infinite;
        #{$self}__circle {
            animation: CircularDash 1.4s ease-in-out infinite;
            stroke-dasharray: 80px, 200px;
            stroke-dashoffset: 0;
        }
    }
    &_static {
        transition: transform .2s ease-in-out;
        #{$self}__circle {
            transition: stroke-dashoffset .2s ease-in-out;
        }
    }
    &_disable-shrink {
        #{$self}__circle {
            animation: none;
        }
    }
    &__svg {
        display: block;
    }
    &__circle {
        width: 100%;
        height: 100%;
        stroke: currentColor;
    }
}