@use "../../styles/common";

$queue-operator-view-border-color: common.$ct-gray-250;
$queue-card-title-font-size: common.$ct-font-size-base * 1.14;

.queueCard {
    :global(.task-card) {
        margin-right: 15px;
    }
    
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px 10px 0 10px;
    }

    &__title {
        font-size: $queue-card-title-font-size;
    }

    &__button {
        padding: 0;

        &:last-child {
            margin-right: 0;
        }
    }

    &__edit {
        color: common.$ct-gray-650;
    }

    &__items {
        display: flex;
        justify-content: space-between;
        padding: {
            top: 20px;
            bottom: 20px;
            left: 35px;
            right: 35px;
        };
    }

    &__button-toggle {
        text-align: center;
        :global(.arrow-toggle) {
            height: 20px;
        }
    }

    &__body {
        &_noActiveEmployees {
            @include common.text-muted;
            display: flex;
            align-items: center;
        }
    }

    &__section {
        padding: {
            bottom: 40px;
        };
    }
    &__sectionHeader {
        padding: {
            bottom: 15px;
        };
        font-size: $queue-card-title-font-size;
        font-weight: 400;
    }
    &__sectionBody {
        &_noActiveEmployees {
            justify-content: center;
        }
        min-height: common.$board-min-height;
    }
    &__operatorsSectionBody {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        :global(.queue-operator-view) {
            border-bottom: 1px solid $queue-operator-view-border-color;
            &:first-child {
                border-top: 1px solid $queue-operator-view-border-color;
            }
        }
    }
}
