@use "../../styles/common";

$logo-icon-size: 32px;

.channelIconLarge {
  width: $logo-icon-size;
  height: $logo-icon-size;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  font-size: $logo-icon-size;

  &_img {
    height: $logo-icon-size - 6px;
    width: fit-content;
  }
}

.msbotIcon, .teamsIcon, .instagramIcon {
  width: fit-content;
  height: $logo-icon-size;
}


.facebookIcon {
  color: #008DFF;
}

.telegramIcon {
  color: #0088CC;
}

.odnoklassnikiIcon {
  color: #FF9800;
}

.webchatIcon {
  color: var(--primary);
}