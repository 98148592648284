@use "../../styles/common" as common;

.pairSettingsControl {
  margin-bottom: 1rem;
  position: relative;

  &:not(:last-child) {
    @include common.section-border;
  }

  &__closeIcon {
    @include common.closeIconControl;
    position: absolute;
    font-size: 22px;
    top: -5px;
    right: 5px;
  }
}