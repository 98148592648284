@use "../../styles/common";

.reportsList {
    list-style: none;
    padding: 0 20px;
    margin: 0;

    &__item:not(:last-child) {
        padding-bottom: 20px;
        border-bottom: 1px solid common.$ct-gray-300;
        margin-bottom: 20px;
    }
}


