@use "../../../../styles/common" as common;

.queueMetric {
  @include common.section-border;
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0 0.75rem;
  margin-bottom: 0.5rem;

  &__header {
    display: flex;
    padding: 0 0 1.2rem;
  }

  &__title {
    flex: 1;
  }

  &__deleteIcon {
    padding: {
      left: common.$input-padding-x;
      right: common.$input-padding-x;
    };
    cursor: pointer;
  }
}