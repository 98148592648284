@use "../../../../../../styles/common";

.requestSettingsSection {
  @include common.section-border;

  &__addSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__buttonIcon {
    padding: 16px 5px;
  }
}