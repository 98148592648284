@use "../../../../styles/common" as common;

.comparison {
    position: relative;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &__text {
        font-size: common.$fz-16;
        height: auto;
        position: relative;
        z-index: 900;
        background-color: common.$white;
        padding: 15px 12px 15px 12px;
    }

    &__close {
        height: 40px;
        border-bottom: 2px common.$ct-red-4 solid;
        margin-bottom: 20px;

        &_added {
            border-bottom: 2px common.$ct-blue-4 solid;
        }
    }

    &__line {
        width: 100%;
        height: 2px;
        background-color: common.$ct-red-4;
        position: absolute;
        margin: 15px 0 15px 0;

        &_added {
            background-color: common.$ct-blue-4;
        }
    }
}
