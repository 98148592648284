.info {
    &__fileLink {
        display: inline-block;
        margin-bottom: 16px;
    }

    &__statistic {
        display: flex;
        align-items: center;
        gap: 24px;
        min-height: 35px;
    }
    
    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
    }

    &__loadBtn {
        margin-left: auto;
        animation: show 0.3s ease 1 forwards;
    }
}

@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
