@use "../../styles/common";

.picker {
    position: relative;
    flex: 1 1 auto;

    &_disabled {
        filter: grayscale(1);
    }

    &__input {
        padding-left: 45px;
    }

    &__icon {
        position: absolute;
        top: 10px;
        left: 20px;
        color: var(--primary);
        font-size: common.$fz-18;
        cursor: pointer;
    }

    &__close {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 40px;
        height: 40px;
        display: flex;
        font-size: common.$fz-14;
        padding: 0;
        align-items: center;
        justify-content: center;

        &:focus {
            outline: 0;
        }

        &_error {
            right: 25px;
        }
    }
}
