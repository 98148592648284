@use "./../../../styles/common";

.table {
    &_empty {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        padding: 50px 0;

        color: common.$ct-gray-650;
        font-size: common.$fz-16;
    }
}