@use "../../../styles/common";

.removableListItem {
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__deleteIcon {
        @include common.closeIconControl;
        font-size: common.$fz-14;

        height: 24px;
        padding: 3px 5px;
        border: 0;

        &:global(.btn-link) {
          color: #4F4F4F;
        }
    }

    &:last-of-type {
        padding-bottom: 16px;
    }
}
