@import "../../styles/common";

.article-view-loader {
  &__header {
    width: 100%;
    height: 40px;
  }

  &__title {
    margin-bottom: 10px;
    width: 100%;
    height: 100px;
  }

  &__content {
    @include article-section;
    width: 100%;
    height: 400px;
  }
}