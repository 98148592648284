@use "../../styles/common";

.subArticles {
    @include common.article-section;

    &__body {
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    &__item {
        width: 100%;
        padding-left: 0;
        color: common.$knowledge-base-main-text;
        text-align: left;
        @include common.no-wrap-text();

        &:hover {
            color: common.$knowledge-base-primary-text;
        }
    }

    &_expanded {
        @include common.article-section-expanded;
    }
}
