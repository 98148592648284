@use "../../styles/common";

.accordionToggle {
  border: none;
  width: 100%;
  padding: 0;
  background: none;
  &:hover {
    background-color: common.$ct-gray-175;
  }
}