::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: $ct-gray-50; 
}

::-webkit-scrollbar
{
	width: 8px;
	height: 8px;
	background-color: $ct-gray-50;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: $ct-gray-450;
}
