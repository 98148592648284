@use "../../styles/common" as common;

.articleActions {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 10px 10px 0;
    gap: 10px;

    &__button {
        width: 210px;
        min-height: 40px;

        &_one {
            width: 100%;
        }
    }
}
