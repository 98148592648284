@use "../../styles/common";

.collapser {
    display: flex;
    flex-flow: column;
    margin: 0 0 17px;

    &__expander {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 1rem;
        padding: 3px 16px;
        transition: all 150ms ease;
        background: #fff;
        user-select: none;
        border-radius: common.$border-radius-lg;

        &:hover {
            background: #F2F2F2;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &__body {
        display: none;
        flex-flow: column;
        margin: 8px 16px;

        &_visible {
            display: flex;
        }
    }
}