@use "../../styles/common";

$user-card-picture-size: 40px;
$user-data-max-width: 120px;

.userCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    $self: &;

    &_short {
        align-items: initial;

        #{$self}__picture {
            margin-right: 0;
        }
    }

    &__picture {
        border-radius: 50%;
        margin-right: 10px;
        font-size: common.$fz-16;

        &_normal,
        &_short {
            @include common.img-size($user-card-picture-size);
        }

        &_small {
            @include common.img-size($user-card-picture-size / 1.2);
        }

        &_large {
            @include common.img-size($user-card-picture-size * 1.2);

            font-size: common.$fz-20;
        }

        &_default {
            user-select: none;
            background-color: #f2c94c;
            display: flex;
            justify-content: center;
            align-items: center;
            color: common.$white;
        }

        &_inactive {
            filter: grayscale(100%) opacity(50%);
        }
        &_small {
            width: 30px;
            height: 30px;
            margin-right: 6px;
        }
    }

    &__pictureContainer {
        position: relative;
    }

    &__indicator {
        position: absolute;
        bottom: 0;
        left: -3px;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: common.$fz-16;
        line-height: 1.125;
    }

    &__introduction {
        user-select: none;
        font-size: common.$fz-16;
        display: flex;
        flex-direction: row;
    }

    &__firstName {
        @include common.no-wrap-text();
        max-width: $user-data-max-width;
        margin-right: 5px;
    }

    &__lastName {
        @include common.no-wrap-text();
        max-width: $user-data-max-width;
        flex: 1 1;
    }

    &__clientFullName {
        @include common.no-wrap-text();
        max-width: $user-data-max-width * 2;
        font-size: common.$fz-16;

        &_large {
            font-size: common.$fz-20;
            max-width: $user-data-max-width * 3;
        }
    }

    &_clickable {
        cursor: pointer;
        @include common.transition-all();
        border-radius: 20px;
        padding-right: 10px;

        &:hover {
            background-color: common.$ct-gray-175;
        }
    }
}
