@use "../../styles/common";

$color-circle-default-size: 22px;
$color-circle-bg-color: common.$body-bg;

.colorCircleInput {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: common.$form-group-margin-bottom;
  margin-top: 6px;
  gap: 16px;
  &__circle {
    width: $color-circle-default-size;
    height: $color-circle-default-size;
    cursor: pointer;
    padding: 0;
    border-radius: 50%;
    transition: all .1s ease-in-out;
    border: 2px solid $color-circle-bg-color;
    &:hover {
      transform: scale(1.1);
    }
    &_selected {
      &:hover{
        transform: none;
      }
    }
  }

  &.disabled {
    .colorCircleInput__circle {
      cursor: default;
      &:hover {
        transform: none;
      }
    }
  }
}