@supports (--css: variables) {
    body {
        margin: 0;
        font-family: var(--font-family-sans-serif);
        @include font-size($font-size-base);
        font-weight: $font-weight-base;
        line-height: $line-height-base;
        color: $body-color;
        text-align: left;
        background-color: $body-bg;
    }
}