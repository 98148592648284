@use "../../../styles/common";
$vertical-space: 24px;

.clientSurvey {
  margin-bottom: 20px;

    &__credentialsContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__dialogHistoryIcon {
        height: 28px;
        width: 28px;
        margin-top: 10px;
        color: #727272;
        cursor: pointer;
        transition: color 0.2s ease-in-out;

        &:hover {
            color: var(--primary);
        }
    }

    &__dialogHistoryTooltip {
        margin-right: 5px;
    }

    &__userCard {
        &_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $vertical-space;
        }
    }

    &__id {
        margin-top: $vertical-space;
        margin-bottom: $vertical-space;
    }

    &__title {
        font-size: common.$fz-18;
        line-height: 1.15;
        font-weight: 500;
        margin-bottom: 24px;
    }

    &__fieldContainer {
        width: 100%;
        position: relative;
        margin-bottom: 20px;

        display: flex;
        flex-direction: row;
        align-items: center;

        &:last-child {
            margin-bottom: 16px;
        }

        :global {
            .form-group {
                margin-bottom: 0;
                width: 100%;
                padding-left: 20px;
                height: 40px;
            }

            .form-control {
                height: 40px;
                color: #333333;
                border-color: common.$ct-gray-600;
            }
        }

        &_increased {
            align-items: flex-start;
            :global {
                .form-group,
                .form-control {
                    height: 90px;
                }
            }
        }
    }

    &__fieldTitle {
        flex: 0 0 30%;
        font-weight: 500;
        color: #333333;
        margin-bottom: 0;
    }

    &__fieldContent {
        width: 100%;
        height: 42px;
        padding: 0 30px 0 20px;
    }

    &__linkAction {
        padding-bottom: 0;
        align-items: start;

        &_clicked {
            color: var(--primary);

            &:global(.btn-link:focus) {
                color: var(--primary);
            }
        }
    }
}
.noFormMessage {
    margin-bottom: 20px;
    color: common.$text-muted;
}
