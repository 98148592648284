@use "../../styles/common" as common;

.articleImportFile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    padding: 9px 22px;
    border: 1px solid common.$ct-gray-300;
    border-radius: 5px;

    &__content {
      display: flex;
      flex-direction: column;

      &__name {
        color: var(--primary);
        word-break: break-all;
      }

      &__size {
        color: common.$ct-gray-600;
      }
    }

    &__delete {
      cursor: pointer;
      margin-left: auto;
      background: none;
      transition: transform .3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
}