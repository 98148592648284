@use "../../../../../styles/common" as common;

.emailChannel {
  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__input {
    flex: 1
  }

  &__deleteIcon {
    padding: {
      left: common.$input-padding-x;
      right: common.$input-padding-x;
    }
    cursor: pointer;
  }
}