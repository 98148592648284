@import "../../styles/common";

$wizard-sidebar-width: 450px;

.project-copy-wizard {
  background-color: $main-container-background;
  min-height: 100vh;
  display: flex;
  &__sidebar {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: {
      left: 60px;
      top: 30px;
    };
    top: 0;
    left: 0;
    background: none;
    width: $wizard-sidebar-width;
    height: 100%;
  }
  &__container {
    min-height: 100vh;
    overflow-y: hidden;
  }
}