@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Ubuntu-Light.ttf') format('ttf');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Ubuntu-Regular.ttf') format('ttf');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Ubuntu-Medium.ttf') format('ttf');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Ubuntu-Bold.ttf') format('ttf');
}