@use "../../../styles/common" as common;

.dictionaryValue {
  @include common.section-border;
  @include common.transition-all;
  background-color: common.$body-bg;

  &:global(.row) {
    padding: 0.75rem 0 0.75rem;
    transition-duration: 0s;
    margin: 0;
  }


  &_isDragging {
    border-top: 1px solid common.$ct-gray-450;
  }

  &__valueInput {
    &:global(.form-group) {
      padding-right: 10px;
      padding-left: 0px;
      flex-basis: 40%;
      margin: 0;
    }

    :global {
      .form-control {
        @include common.no-outline-focus;
        border: none;
        padding: 0;

        &.is-invalid {
          &, &::placeholder {
            color: common.$form-feedback-invalid-color;
          }
        }
      }

      .invalid-feedback {
        display: none;
      }
    }
  }

  &__inputCol {
    &:global(.col) {
      padding: {
        left: 0;
        right: 0;
      };
    }

    &:global(.col-md-4) {
      padding: {
        left: 0;
        right: 0;
      };
    }
  }

  &__tagInput {
    &:global(.tag-input) {
      @include common.no-outline-focus-within;
      border: none;
      flex: 1;
    }

    :global(.tag-input__tag) {
      border-color: common.$ct-gray-450;
    }

    :global(.tagIconRemove) {
      color: common.$ct-black-50;
    }
  }

  &__dragIcon {
    &:global(.col) {
      font-size: 18px;
      padding: common.$input-padding-y 0 0 common.$input-padding-x;
      color: common.$ct-gray-600;
      max-width: 35px;
    }
  }

  &__deleteIcon {
    padding: {
      top: common.$input-padding-y;
      left: common.$input-padding-x;
      right: common.$input-padding-x;
    };
    cursor: pointer;

    &_hidden {
      visibility: hidden;
      pointer-events: none;
      cursor: inherit;
    }
  }
}
