@use "../../styles/common" as common;

$circle-size: 30px;

$inactive-color: common.$ct-gray-250;
$active-color: var(--primary);
$text-size: common.$font-size-base;

.stepper {
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin: 40px 0 60px;

  &__stepContainer {
    display: flex;
    flex-direction: column;

    &:not(:first-child):before {
      content: "";
      flex: 40px;
      width: 2px;
      background-color: $inactive-color;
      margin-left: $circle-size / 2 - 1;
    }

    &_selected {
      &:not(:first-child):before {
        background-color: $active-color;
      }
    }
  }

  &__step {
    display: flex;
    align-items: center;
  }

  &__text {
    margin-left: 16px;
    font-size: 16px;
    line-height: 18px;
  }

  &__circle {
    width: $circle-size;
    height: $circle-size;
    padding: 0;
    border-radius: 50%;
    transition: all .3s ease-in-out;
    background-color: $inactive-color;
    color: common.$white;
    font-size: $text-size;

    display: flex;
    align-items: center;
    justify-content: center;

    &_selected {
      border: 2px solid common.$body-bg;
      background-color: $active-color;
      box-shadow: 0 0 0 1px $active-color;
      z-index: 1;
    }
  }
}