@import "../../styles/common";

.operator-queue-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &__name {
        font-size: $ct-font-size-base * 1.14;
        overflow: hidden;
    }
}