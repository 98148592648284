@use "../../../../../styles/common";

$action-border: 1px solid common.$ct-gray-200;
$side-padding: 16px;
$user-spacing: 16px;
$outgoing-bg: common.$ct-primary-3;
$incoming-bg: common.$ct-gray-175;

.messageBasic {
    @include common.message;

    $self: &;

    &_out {
        flex-direction: row-reverse;
        #{$self}__content {
            background-color: $outgoing-bg;
            border-radius: 5px 0 5px 5px;
        }
        #{$self}__actions {
            border-radius: 5px 0 5px 5px;
        }
        #{$self}__user {
            margin-right: 0;
            margin-left: $user-spacing;
        }
    }

    &__body {
        @include common.messageBody;

        &_out {
            padding-left: 30px;
        }
    }

    &__user {
        margin-right: $user-spacing;
        margin-left: 0;
    }

    &__content {
        @include common.messageContent($side-padding, $incoming-bg);

        &Wrapper {
            width: 100%;
        }

        &_out {
            align-self: flex-end;
        }

        &_wide {
            width: 100%;

            img {
                max-width: 100%;
            }
        }

        &Inner {
            &_compact {
                max-height: 315px;
                overflow: hidden;
                white-space: break-spaces;
            }
        }

        &Actions {
            > button {
                outline: none;
                border: none;
                color: common.$ct-gray-600;
                padding: 0;
                margin-top: 20px;
                background: none;

                &:hover {
                    color: common.$ct-gray-500;
                }
            }
        }
    }

    &__text {
        white-space: break-spaces;
        word-break: break-word;

        > a {
            color: common.$rme-link;
        }
    }

    &__edited {
        color: common.$ct-gray-600;
        text-align: end;
    }

    &__section {
        margin-top: 8px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__actions {
    }

    &__action {
        width: 100%;
        padding: 10px $side-padding;
        color: var(--primary);
        background-color: common.$white;
        margin-bottom: 9px;
        text-align: center;
    }

    &__attachments {
        display: grid;
        grid-template-columns: repeat(auto-fit, 160px);
        grid-auto-rows: 160px;
        gap: 16px;
    }

    &__attachment {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__footer {
        @include common.messageFooter(common.$ct-gray-650, common.$fz-12);

        &_out {
            align-self: flex-end;
        }
    }

    &__info {
        margin-right: 6px;
        padding-top: 2px;

        &:last-child {
            margin-right: 0;
        }

        &_extraPadding {
            margin-right: 12px;
        }

        &Channel {
            margin-right: 12px;
        }
    }

    &__icon {
        margin-top: 4px;

        &_operator {
            color: var(--primary);
            font-size: 16px;
        }
    }

    &__markdownContainer {
        & > div {
            background: none;

            :global(.heading-actions) {
                display: none;
            }
        }
        &_withButtons > div {
            min-width: 400px;
        }
    }

    &__deletedText {
        color: common.$ct-editor-text;

        &_whiteFont {
            color: common.$white;
        }
    }
}
