@import "../../styles/common";

.parameter-value-control {
  font-size: $font-size-base;
  margin-top: 5px;
  margin-bottom: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .close {
    font-size: 1.6rem;
    font-weight: 400;
    margin-right: 2px;

    &:focus {
      outline: 0;
    }
  }
}