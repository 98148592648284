@use "../../styles/common";

$sidebar-side-padding: 5px;
$sidebar-main-color: common.$ct-gray-100;
$sidebar-inner-color: common.$ct-gray-50;
$sidebar-horizontal-padding: common.$sidebar-vertical-padding * .8;

@mixin sidebar-padding {
    padding: {
        left: $sidebar-side-padding;
        right: $sidebar-side-padding;
    }
}

.sidebar {
    position: fixed;
    top: var(--header-height);
    left: 0;
    height: common.$height-without-header;
    width: common.$sidebar-width;
    overflow: hidden;
    border-right: common.$sidebar-divider;
    padding: {
        top: common.$sidebar-vertical-padding;
        bottom: $sidebar-horizontal-padding;
    }
    @include common.flex-column;
    @include common.transition-all();
    $self: &;

    &_opened {
        width: common.$sidebar-opened-width;
    }

    &__header {
        @include sidebar-padding;
    }

    &__content {
        @include sidebar-padding;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &__logo-container {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    &__divider {
        @include sidebar-padding;
        margin-bottom: common.$menu-item-bottom-spacing;
        width: 100%;

        &-element {
            border-bottom: common.$sidebar-divider;
            width: 100%;
        }
    }
    &__footer {
        @include sidebar-padding;
        padding: {
            top: common.$menu-item-bottom-spacing;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}