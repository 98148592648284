@import "../../styles/common";

.articleAnswersForm {
  padding: 15px;
  box-shadow: $medium-highlight-box-shadow;
  border-radius: $border-radius;
  margin-bottom: 15px;
  &__addSlot {

  }
  &__slots {

  }
  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  &__cancelButton {
    margin-right: 10px;
  }
}

.articleAnswersFormItem {
  padding: 5px 0 15px;
  border-top: 1px solid $ct-gray-300;
  .icon-button {
    padding-right: 5px;
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  &__title {
    @include no-wrap-text();
    font-size: $font-size-base * 1.15;
  }
  &__input {
    margin-bottom: 10px;
  }
  &__inputError {
    color: $danger;
    font-size: $font-size-sm;
  }
  &__value {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__valuePayload {

  }
  &__valueRemove {
    .icon-button {
      color: $body-color;
    }
  }
}