@import "../../styles/common";

$validatable-input-placeholder-color: $ct-gray-650;

$validatable-input-alert-width: 1em;
$validatable-input-alert-right: calc(#{$input-padding-y} + #{$input-padding-x} / 4);
$validatable-input-alert-top: calc(50% - #{$validatable-input-alert-width} / 2);

.validatable-input {
    position: relative;
    $self: &;

    &_invalid {
        #{$self}__alert {
            visibility: visible;
            color: $form-feedback-icon-invalid-color;
        }
    }

    &_with-alert {
        .form-control {
            &.is-invalid {
                background: none;
            }
        }
    }

    &__wrap {
        position: relative;
        .select-input,
        .select-input.is-invalid {
            background-image: none;
            appearance: none;
        }
    }

    &__alert {
        visibility: hidden;
        width: $validatable-input-alert-width;
        position: absolute;
        top: $validatable-input-alert-top;
        right: $validatable-input-alert-right;
    }

    &__delete-icon {
        @include closeIconControl;
        position: absolute;
        font-size: 22px;
        top: -5px;
        right: 5px;
    }

    &__icon {
        @include iconWrapper();
        position: absolute;
        top: 8px;

        &_alert {
            color: $form-feedback-icon-invalid-color;
            right: 32px;
        }

        &_arrow {
            right: 8px;
        }
    }

    &__maxSymbolsCountLabel {
        position: absolute;
        bottom: 4px;
        right: 6px;
        color: $validatable-input-placeholder-color;
        font-size: $fz-13;

        &_error {
            color: $form-feedback-icon-invalid-color;
            bottom: 0;
            right: 3px;
            font-size: $fz-12;
        }
    }

    .form-label > svg {
        @include input-info-icon;
    }

    .form-control::placeholder {
        color: $validatable-input-placeholder-color;
    }
}
