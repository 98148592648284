@import "../../styles/common";

.article-user-views-popover {
  width: 350px;
  max-width: 350px;
  &__count {
    @include article-action();
    color: $knowledge-base-main-text;
    border: none;

    &:hover {
      background-color: transparent;
    }
  }

  &__icon {
    margin-right: 5px;
  }
}