@use "../../../../styles/common";


@keyframes show {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.bounceIndicator {
    width: 26px;
    height: 26px;
    position: relative;

    &__wave {
        position: absolute;
        border-radius: 50%;
        background-color: rgba(common.$white, 0.5);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:nth-child(1) {
            width: 8px;
            height: 8px;
            background-color: common.$white;
        }

        &:nth-child(2) {
            width: 18px;
            height: 18px;
            background-color: rgba(common.$white, 0.14);
            animation: show 1.5s infinite linear;
        }

        &:nth-child(3) {
            width: 26px;
            height: 26px;
            background-color: rgba(common.$white, 0.3);
            animation: show 1.5s infinite linear 0.25s;
        }
    }
}
