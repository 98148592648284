@use "../../styles/common";

$queues-view-border-color: common.$ct-gray-250;

.queuesListLoader {

    &__search {
        margin-bottom: 30px;
        height: 1.8rem;
        width: 100%;
    }

    &__item {
        border-bottom: 1px solid $queues-view-border-color;

        &:first-child {
            border-top: 1px solid $queues-view-border-color;
        }
    }
}