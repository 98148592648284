@import "../../styles/common";

$command-form-icon-size: 38px;

.command-form {
  @include page-layout-sidebar();
  @include sidebar-form();

  &__content {
    padding-top: 10px;
  }
}