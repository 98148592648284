@use "../../styles/common";

$size: 16px;
$bg-color: common.$danger;
$font-size: common.$ct-font-size-base * .75;

.indicator {
  border-radius: 8px;
  background-color: $bg-color;
  height: $size;
  color: common.$body-bg;
  font-size: $font-size;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3px;
}