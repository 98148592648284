@import "../../styles/common";

.knowledge-base-permissions {
  max-height: 285px;
  margin-bottom: 20px;
  overflow-y: auto;

  &__item:not(:last-child) {
    margin-bottom: 16px;
  }
}
