@import "../../styles/common";

.operator-info {
    @include page-layout-sidebar();
    &__section {
        margin-bottom: 16px;
        border-bottom: 1px solid $ct-gray-300;

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }

    &__group {
        display: flex;
        justify-content: flex-start;

        > * {
            margin-right: 97px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .info-field {
        margin-bottom: 16px;
    }
}