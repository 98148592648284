@import "../../styles/common";

.agent-form {
  @include page-layout-sidebar();
  @include sidebar-form();

  &__checkbox {
    margin-bottom: 1rem;
  }

  &__content {
    label {
      max-width: 340px;
    }
  }

  &__template-textarea {
    textarea {
      resize: none;
      min-height: 80px;
    }
  }

  &__message-textarea {
    textarea {
      resize: none;
      min-height: 64px;
    }
  }

  &__json-body-template {
    textarea {
      min-height: 120px;

      &:disabled {
        resize: none;
      }
    }
  }

  &__add-section {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
  }

  &__button-icon {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    font-size: 1rem;
  }

  &__radio-buttons {
    display: flex;
    justify-content: space-between;
  }

  &__close-icon {
    @include closeIconControl;
  }

  &__check-box-margin {
    margin-bottom: 1rem;
  }

  &__margin-top {
    margin-top: 0.5rem;
  }

  .form-control::placeholder {
    color: $ct-gray-650;
  }

  &__request-subtitle {
    display: block;
    margin-bottom: 8px;
    font-size: $font-size-base * 1.14;
  }

  &__tags {
    margin-bottom: 20px;
  }

  &__tag {
    @include selectedTag;
    margin-top: 0;
    max-width: 150px;
  }

  &__adfsagent-callback {
    padding: 1.5rem 0 40px;
  }
}