@import "../../styles/common";

$search-articles-input-border-color: $knowledge-base-sub-text;
$search-articles-icon-color: $knowledge-base-sub-text;
$search-articles-query-placeholder-color: $knowledge-base-sub-text;

.search-articles-input {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid $search-articles-input-border-color;
  border-radius: 5px;

  &__query {
    @include no-outline-focus();
    @include no-underline-focus();
    flex: 1;
    padding: 0;
    height: auto;
    border: none;
  }

  input::placeholder {
    color: $search-articles-query-placeholder-color;
  }

  &__search-icon {
    @include article-action
  }

  &__search, &__clear {
    @include article-action;
    padding: 5px;
    color: $search-articles-icon-color;
  }

  &__clear-icon {
    display: flex;
    justify-content: center;
    height: 14px;
    width: 14px;
  }
}

.search-articles-input-container {
  &__enable-removed {
    margin: 0 15px 10px;
  }
}