@use "../../../../styles/common";
@use "../../variables.scss";

.view {
    font-size: common.$fz-14;
    line-height: 16/14;

    &__header {
        margin-bottom: 30px;
        min-height: 38px;
    }

    &__info {
        padding: 0 20px;
        margin-bottom: 24px;
    }

    &__skeleton {
        padding-top: 10px;
    }

    &__skeletonItem {
        height: 50px;
        margin-bottom: 10px;
    }

    &__noItems {
        padding: 0 20px;
    }

    &__pagination {
        display: flex;
        justify-content: end;
    }
}
