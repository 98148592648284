@use "../../styles/common" as common;

$title-font-size: common.$ct-font-size-base * 2.57;
$message-font-size: common.$ct-font-size-base * 1.57;
$content-width: 444px;

.articleBlocked {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;

  &__header {
    max-width: $content-width;
    margin-bottom: 20px;
  }

  &__body {
    max-width: $content-width;
  }

  &__title {
    font-size: $title-font-size;
    user-select: none;
  }

  &__message {
    font-size: $message-font-size;
    user-select: none;
  }

  &__image {
    margin-top: 40px;
    user-select: none;
  }
}