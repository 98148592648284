@use "../../../../styles/common";

$reports-indicators: (
    "waiting": common.$ct-orange-3,
    "progress": common.$ct-purple-1,
    "ready": common.$ct-green-7,
    "failed": common.$ct-red-4,
    "export": common.$ct-blue-3
);

.report {
    font-size: common.$fz-16;
    line-height: 18/16;

    &__line {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &__status {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    &__date {
        color: common.$ct-gray-600;
    }

    &__indicator {
        border-radius: 50%;
        width: 8px;
        height: 8px;

        @each $status, $color in $reports-indicators {
            &_#{$status} {
                background-color: #{$color};
            }
        }
    }
}
