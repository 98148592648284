@use "../../styles/common";

.operatorTasks {
  &__empty {
    display: flex;
    justify-content: center;

    font-size: common.$fz-16;
    color: common.$ct-gray-650;
  }
}