@import "../../styles/common";

$tag-input-placeholder-color: $ct-gray-600;

.tag-input.form-control {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
}

.tag-input {
    @include enable-focus-within;

    &__input {
        flex: 1;
        border: none;
        font-size: $ct-font-size-base;
        background-color: transparent;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $tag-input-placeholder-color;
        }
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin-top: -5px;
    }

    &__tag {
        margin-right: 5px;
    }

    &__error {
        color: $danger;
        font-size: $form-feedback-font-size;
        margin-top: $form-feedback-margin-top;
    }
}