@import "../../styles/common";

.article-file {
  &__attachment {
    margin: 2em 0;
  }
}

.article-file-menu-icon {
  width: 24px!important;
}
