// use this style sheet for bootstrap class overrides

// buttons
.btn-light {
  color: var(--primary);

  &:not(:disabled):hover {
    color: var(--primary);
  }
}

.btn-primary, .btn-primary:disabled {
  background-color: var(--primary);
  border-color: var(--primary);

  &:not(:disabled):not(.disabled):active, &:hover, &:focus {
    background-color: color-mix(in lab, var(--primary) 100%, #000000 30%);
    border-color: color-mix(in lab, var(--primary) 100%, #000000 30%);
  }
}

.show > .btn-primary.dropdown-toggle {
    background-color: var(--primary);
    border-color: var(--primary);
}

.dropdown-item:active {
  background-color: var(--primary);
}

.custom-control-input {
  &:checked ~ .custom-control-label::before {
    background-color: var(--primary);
    border-color: var(--primary);
  }
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: color-mix(in lab, var(--primary) 50%, #000000 0%);
}

.btn-white {
  background-color: $ct-gray-50;
  border-color: $ct-gray-300;
  color: var(--primary);

  &:hover {
    background-color: $ct-gray-200;
  }

  &:focus {
    box-shadow: none;
    text-decoration: none;
  }
}

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);

  &:not(:disabled):not(.disabled):active, &:hover {
    background-color: var(--primary);
    border-color: var(--primary);
  }
}

.btn-link {
  color: $gray-500;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    color: $body-color;
    text-decoration: none;
    box-shadow: none;
  }

  &:not(:disabled):hover {
    color: var(--primary);
  }
}

.btn-outline-light {
  color: $ct-gray-600;
  border-color: $ct-gray-300;

  &:hover {
    color: var(--primary);
    background-color: $ct-gray-300;
    border-color: $ct-gray-300;
  }

  &:disabled, &.disabled {
    color: $ct-gray-300;
    background-color: transparent;
  }
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: var(--primary);
  background-color: $ct-gray-300;
  border-color: $ct-gray-300;
}

.btn-light-danger {
  @include button-variant($ct-red-6, $ct-red-6, $ct-red-6);
  color: $ct-red-4;

  &:hover {
    color: $ct-red-4;
  }

  &:focus,
  &.focus {
    color: $ct-red-4;
  }

  &:disabled,
  &.disabled {
    color: $ct-red-4;
    pointer-events: none;
  }
}

.btn-light-danger:not(:disabled):not(.disabled):active,
.btn-light-danger:not(:disabled):not(.disabled).active,
.show > .btn-light-danger.dropdown-toggle {
  color: $ct-red-4;
}

.form-check-input {
  background-color: $ct-red-4;
}

.form-control {
  line-height: 1rem * 18 / 16;
}

.modal-backdrop {
  z-index: 5040;
}

.modal.fade {
  z-index: 5050;
}

input, button, select, optgroup, textarea {
  color: inherit;
}

a {
  color: var(--link);
}

a:hover {
  color: color-mix(in lab, var(--link) 100%, #000000 80%);
}

.nav-item {
  & > a:hover {
    color: color-mix(in lab, var(--primary) 100%, #000000 80%);
  }
}