@use "../../styles/common" as common;

.nextAnswer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 3rem 0;
  outline: 0;
  opacity: .5;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    border-top: 1px dashed common.$knowledge-base-sub-text!important;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 0 .5em;
    line-height: 1.5em;
    color: common.$knowledge-base-sub-text;
    background-color: common.$white;
  }
}
