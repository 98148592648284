@use "../../../../styles/common";

.emptyView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 16px;
    color: common.$ct-gray-650;

    &__text {
        font-size: common.$fz-18;
    }
}
