@import "../../../../styles/common";

$agent-node-header-bg-color: $ct-editor-1;
$agent-node-width: 290px;
$agent-node-icon-size: 36px;
$agent-node-text-width: 160px;

.react-flow {
  .agent-node {
    width: $agent-node-width;
    border-radius: 5px;

    &__header {
      background-color: $agent-node-header-bg-color;
      border-radius: 5px 0 0 0;
    }

    &__content {
      background-color: $body-bg;
      padding: 20px 20px 16px 20px;
      min-height: 70px;
    }

    &__agent {
      display: flex;
      align-items: center;
      padding-bottom: 25px;
    }

    &__agent-content {
      display: flex;
      flex-direction: column;
      margin-left: 11px;
      line-height: 16px;
    }

    &__agent-title {
      @include text-truncate;
      width: $agent-node-text-width;
      margin-bottom: 5px;
    }

    &__agent-type {
      @include text-muted;
      width: $agent-node-text-width;
    }

    &__agent-icon {
      width: $agent-node-icon-size;
      height: $agent-node-icon-size;
      background-color: $agent-node-header-bg-color;
      border-radius: 50%;
      font-size: 16px;

      display: flex;
      align-items: center;
      justify-content: center;

      .bss-agent {
        font-size: 12px;
      }
    }
  }
}