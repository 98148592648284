.modalBox {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 680px;

    &__header {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 20px;

        h5 {
            font-size: 22px;
            margin: 0;
        }
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }

    &__content {
        min-height: 50px;
    }
}