@use "../../styles/common";

$icon-width: 22px;

$icon-color: common.$ct-black-50;
$icon-disabled-color: common.$text-muted;

.idField {
  position: relative;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
  color: $icon-color;
  $self: &;

  &_disabled {
    color: $icon-disabled-color;
    pointer-events: none;
  }

  &__img {
    width: $icon-width;
    height: $icon-width;
    margin-right: 8px;
  }

  &__data {
    display: inline-block;
    cursor: copy;
    max-width: 373px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
