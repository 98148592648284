@use "../../styles/common";

$title-spacing: common.$ct-font-size-base * 1.28;

.fullscreenError {
  &__content {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  &__title {
    user-select: none;
    margin-bottom: $title-spacing;
  }
  &__image {
    margin-top: 10px;
    user-select: none;
  }
  &__message {}
}