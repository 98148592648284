@import "../../styles/common";

.catalogs-loader {
  padding: 0 25px;

  &__title {
    width: 250px;
    height: 20px;
    margin-bottom: 20px;
  }

  &__item {
    margin: 0 0 20px 0;
  }
}