@use "../../styles/common";

.articlePublishInfo {
  display: flex;
  flex-direction: column;
  position: relative;

  top: -20px;
  font-size: common.$fz-16;

  &__label {
    color: common.$ct-gray-600;
  }

  &__date {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}