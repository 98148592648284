@import "../../../../styles/common";

.scenario-editor {
  .common-node {
    @include card-border;
    transition: $transition-base;
    background-color: transparentize($body-bg, 0.3);

    &_selected {
      box-shadow: $medium-highlight-box-shadow;

      .btn-close {
        opacity: 1;
        pointer-events: initial;
      }
    }

    &__target-handle {
      @include transition-all(.3s);
      left: -$scenario-node-target-handle-size / 2;
      background-color: initial;
      height: $scenario-node-target-handle-size;
      width: $scenario-node-target-handle-size;
      border-radius: 50%;
      border: none;

      &.connectable {
        cursor: pointer;
      }
    }
  }

  &_connecting {
    .common-node {
      &__target-handle {
        background-color: $scenario-node-target-handle-color;
      }
    }
  }
}
