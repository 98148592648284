
.imageUploadModal {
    z-index: 3000;

    &__body {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__btnSelect {
        margin-bottom: 20px;
    }

    &__btnDefault {
        margin-bottom: 20px;
    }

    &__btnClose {

    }

    &__fileInput {
        display: none;
    }
}