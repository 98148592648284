@import "../../styles/common";

$category-hover-color: $ct-gray-175;

.catalog {
  padding-bottom: 30px;
  height: auto;

  div {
    &[data-react-beautiful-dnd-draggable] {
      &:hover {
        background-color: $category-hover-color;
      }
    }
  }
}
