@import "../../styles/common";

.sidebar-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .top-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        .title-container {
            padding-left: $sidebar-item-left-padding;
            &__item {
                @include sub-small-title;
            }
        }
        .title-container-loading {
            height: 24px;
            border-radius: $border-radius;
            width: 70%;
            @include loading-element;
        }
        .top-container__top-element {
            margin-left: 8px;
        }
    }
    .middle-container-loading {
        height: 200px;
        border-radius: $border-radius;
        width: 100%;
        @include loading-element;
    }
}