.collapser {
    :global(.form-group:last-child) {
        margin: 0;
    }

    & &__filter {
        margin-bottom: 6px;
    }

    &__expander {
        justify-content: space-between;
    }
}
