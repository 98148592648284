@use "../../styles/common";

$icon-size: 38px;
$mini-icon-size: 22px;
$title-font-size: common.$font-size-base * 1.27;
$text-max-width: 303px;

.typeFormCard {
    @include common.transition;
    @include common.card-border;

    padding: 20px;
    overflow: visible;
    cursor: pointer;
    margin-bottom: 16px;
    border-radius: 5px;
    display: flex;

    $self: &;

    &:hover {
        box-shadow: common.$medium-highlight-box-shadow;
    }

    &__text {
        display: flex;
        flex-direction: column;
        max-width: $text-max-width;
        justify-content: center;
    }

    &__title {
        font-size: $title-font-size;
        line-height: 18px;
        font-weight: 500;
    }

    &__desc {
        line-height: 16px;
        margin-top: 8px;
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--primary);
        margin-right: 14px;
        width: $icon-size;
        height: $icon-size;

        :global(.svg-inline--fa) {
            font-size: 1.8rem;
        }
    }

    &_mini {
        padding: 14px 20px;

        #{$self}__title {
            font-size: common.$font-size-base;
        }

        #{$self}__desc {
            @include common.text-muted;
            margin-top: 3px;
        }

        #{$self}__icon {
            margin-top: 3px;
            height: $mini-icon-size;
            width: $mini-icon-size;

            :global(.svg-inline--fa) {
                font-size: 20px;
            }
        }
    }
}
