@use "../../styles/common";

$title-font-size: common.$ct-font-size-base * 1.43;

.calendar-modal {
  &:global(.modal-dialog) {
    margin-top: 10%;
    max-width: 710px;
  }

  .modal-content {
    border-radius: 5px;
  }

  .modal-body {
    padding: 20px;
    height: 360px;
  }

  .modal-title {
    font-weight: 500;
    font-size: $title-font-size;
    line-height: 22px;
  }

  .modal-footer {
    padding: 30px;
  }

  &__content {
    display: flex;
    width: 100%;
    height: 360px;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;

    width: 152px;
    border-right: common.$sidebar-divider;
    padding: 20px 20px 10px 20px;
  }

  &__date-picker {
    display: flex;
    flex-direction: column;
    padding: 20px 30px 50px;
  }

  :global(.calendar-datepicker) {
    font-family: inherit;
    font-size: common.$font-size-base;
    border: none;
  }
}