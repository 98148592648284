.imageAttachment {
    &__content {
        max-width: 100%;
        max-height: 100%;
    }

    &__title {
        display: block;
        word-break: break-word;
    }
}