@import "../../../../styles/common";

.react-flow {
  .add-button-node {
    &__btn {
      background-color: $body-bg;
      display: flex;
      justify-content: center;
      padding: 3px 5px;
    }

    &__icon {
      margin-right: 0;
      font-size: 16px;
    }

    &__handle {
      visibility: hidden;
    }
  }
}