@use "../../../styles/common";

.form {
    font-size: common.$knowledge-base-form-font-size;

    &__footer {
        display: flex;
        justify-content: flex-end;
    }

    &__cancel {
        margin-right: 15px;
    }

    &__tags {
        margin-bottom: 20px;
    }

    &__tag {
        margin: 0 16px 0 0;
        border: none;
        max-width: 150px;

        svg {
            color: common.$ct-black-50;
        }
    }
}
