@use "../../styles/common";

.message {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    .firstMessage {
        margin: 12px 0 0 0;
    }
    
    .secondMessage {
        margin: 16px 0 0 0;
    }
}
