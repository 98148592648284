@import "../../../../styles/common";

$scenario-node-button-item-height: 32px;

.scenario-editor {
  .button-item {
    @include card-border();
    position: relative;
    min-height: $scenario-node-button-item-height;
    background-color: $body-bg;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;

    display: flex;
    align-items: center;

    &__content {
      text-align: center;
      width: 90%;
      padding: 2px 0 4px;
    }

    &__handle {
      @include scenario-handle;
    }

    .btn-close {
      float: right;
      color: $scenario-node-controls-color;
      padding: 0;
      margin-right: 15px;
      font-size: 0.8rem;

      @include transition;
      opacity: 0;
      pointer-events: none;
    }

    &_removable {
      .btn-close {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }

  &_not-editable {
    .button-item {
      pointer-events: none;
    }
  }
}