@import "../../styles/common";

$category-hover-color: $ct-gray-175;
$category-selected-color: $ct-gray-175;
$category-add-size: 14px;

.category {
  @include flex-start-row;
  align-items: center;
  font-size: 14px;
  padding: 2px 0 2px 20px;
  $self: &;

  &:hover {
    cursor: pointer;
    background-color: $category-hover-color;

    .category-action {
      display: block;
    }
  }

  &_selected {
    background-color: $category-hover-color;
    color: var(--primary);
  }

  &_extensions-shown {
    .category-action {
      display: block;
    }
  }

  &__drag {
    cursor: grab;
    background-color: $category-hover-color;
    opacity: 0.8;
    @include transition();
  }

  &__toggle {
    padding: 5px;

    &:hover #{$self}__arrow {
      color: $knowledge-base-primary-text;
    }
  }

  &__toggle-icon {
    @include flex-center-row;
    align-items: center;
    height: 20px;
    width: 20px;
  }

  &__arrow,
  &__article {
    color: $knowledge-base-sub-text;
    @include transition-all();
  }

  &__arrow {
    &_expanded {
      transform: rotate(90deg);
    }
  }

  &__title {
    flex: 1;
    padding: 5px 5px 5px 0;
    @include no-wrap-text();
    @include transition-all();

    &_isOpenedArticle {
      flex: 1;
      padding: 5px 5px 5px 0;
      color: var(--primary);
      @include no-wrap-text();
      @include transition-all();
    }

    &_leaf {
      margin-left: 30px;
    }

    &:hover {
      transition: none;
    }
  }

  &__title.catalog__title {
    font-size: 14px;
    color: $knowledge-base-sub-text;
    text-transform: uppercase;

    &:hover {
      transition: none;
      color: $knowledge-base-sub-text;
    }
  }

  &__root {
    padding-left: 0;
  }

  &_selected {
    background-color: $category-selected-color;
  }
}
