@import "../../styles/common";

$wizard-redirect-image-size: $project-image-height;
$wizard-redirect-project-name-size: $sidebar-width;

.wizard-redirect-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: $border-radius;
  transition: $transition-base;
  &__icon {
    color: $gray-400;
    height: $wizard-redirect-image-size;
    width: $wizard-redirect-image-size;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__icon-svg {
    height: 100%;
    width: 100%;
    &.svg-inline--fa {
      width: 100%;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
  }
  &__text-title {
    margin: 0;
  }
  &__text-project-name {
    font-size: $sub-text-size;
    color: $sub-text-color;
    width: $wizard-redirect-project-name-size;
    @include no-wrap-text()
  }
}