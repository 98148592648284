@use "../../styles/common";

$notification-section-border-color: common.$ct-gray-250;
$notification-section-spacing: 20px;
$notification-section-vertical-spacing: common.$ct-font-size-base * .5;
$notification-section-title-size: common.$ct-font-size-base * 1.143;

.notificationSection {
  border-bottom: none;
  padding: 0 $notification-section-spacing;

  :global(.form-accordion__header) {
    padding: {
      left: $notification-section-spacing;
      right: $notification-section-spacing;
    };
  }

  :global(.form-accordion__title) {
    font-size: $notification-section-title-size;
  }

  &__mainItemsList {}

  &__restItemsToggle {
    padding: {
      left: $notification-section-spacing;
      right: $notification-section-spacing;
      top: $notification-section-vertical-spacing;
      bottom: $notification-section-vertical-spacing;
    };
    color: common.$text-muted;
    cursor: pointer;
    @include common.transition();
    &:hover {
      color: var(--primary)
    }
  }

  & &__item {
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid $notification-section-border-color;
    &:first-child {
      border-top: 1px solid $notification-section-border-color;
    }
  }

  &__restItemsContainer &__item:first-child {
    border-top: none;
  }
}