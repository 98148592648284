@import "../../styles/common";

$section-margin-bottom: 1.5rem;
$queue-form-font-size: $ct-font-size-base;

.queue-form {
    @include page-layout-sidebar();
    @include sidebar-form();
    font-size: $queue-form-font-size;

    &__toggle {
        margin-bottom: $section-margin-bottom;
        display: flex;
        align-items: center;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .toggle {
        @include no-outline-focus;

        &__label {
            margin-left: 13px;
            margin-bottom: 0;
        }
    }

    .add-interval-wrapper {
        margin-bottom: 14px;
    }
}