@use "../../styles/common";

$message-input-vertical-spacing: 20px;
$advanced-input-height: 3.5rem;

.messageInput {
  &__user {
    margin-bottom: $message-input-vertical-spacing;
  }
  &__control {
    &_advanced {
      height: $advanced-input-height;
      overflow: auto;
      border: 1px solid common.$ct-gray-650;
      border-radius: common.$border-radius;
      & > div:first-child {
        height: 100%;
      }
    }
  }
  &__buttonGroup {
    margin-top: $message-input-vertical-spacing;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  &__submit {
    margin-left: 10px;
  }
}