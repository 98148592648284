@import "../../styles/common";

.article-status {
  display: flex;
  align-items: center;
  white-space: nowrap;

  $self: &;
  @each $status, $value in $knowledge-base-status {
    &_#{$status} {
      #{$self}__icon {
        color: $value;
      }
    }
  }

  &__icon {
    margin-right: 5px;
  }
}