@use "../../styles/common";

$wizard-form-stage-width: 400px;
$wizard-form-stage-title-font-size: common.$ct-font-size-base * 2;
$wizard-form-stage-description-font-size: common.$ct-font-size-base;

.wizardFormStage {
  width: $wizard-form-stage-width;

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  &__title {
    font: {
      size: $wizard-form-stage-title-font-size;
      weight: 500;
    }
  }
  &__description {
    margin-top: 10px;
    font: {
      size: $wizard-form-stage-description-font-size;
    }
    color: common.$text-muted;
  }
  &__body {
    margin-bottom: 30px;
    :global(.custom-control) {
      margin-bottom: 20px;
    }
    :global(.form-control) {
      overflow: auto;
      max-height: 150px;
      resize: none;
    }
  }
  &__message {
    margin-top: 10px;
    color: common.$sub-text-color;
    font: {
      size: common.$sub-text-size;
    }
  }
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__backButton, &__nextButton {
    flex: 1;
  }
  &__backButton {
    margin-right: 10px;
  }
}
