
.createSharingLinkForm {
    &__title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .5rem;
    }
    &__label {
        margin: 0;
    }
    &__addButton {
        border: none;
    }
}