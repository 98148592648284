@import "../../styles/common";

.agentFormLoader {
  padding: 0 22px 0;
  display: flex;
  flex-direction: column;

  &__header {
    height: 50px;
    margin-bottom: 1.6rem;
  }

  &__item {
    height: $input-height;
    margin-bottom: 1rem;
  }
}