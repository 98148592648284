@use "../../styles/common";

$notification-card-font-size: common.$ct-font-size-base;

.notificationCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    width: 100%;
    font-weight: 200;
    font-size: $notification-card-font-size;
    margin: {
        bottom: 60px;
    };
    border-radius: common.$border-radius;
    background-color: common.$white;
    box-shadow: common.$highlight-box-shadow;
    &__icon {
        margin-right: 20px;
        color: common.$danger;
    }
    &__message {
        text-align: left;
    }
}