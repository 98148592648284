@import "../../styles/common";

$article-type-width: 100px;

.article-kind-select {
  width: auto;
  display: inline;
  background-color: $ct-primary-1;


  &__toggle {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: $article-type-width;
  }

  &__text {
    @include text-truncate();
    padding-right: 0px;
  }

  &__icon {
    font-size: 20px;
    line-height: 20px;
    float: right;
  }

  &__menu {
    @include mini-scrollbar();

    max-height: 250px;
    overflow-y: auto;
  }

  &__item {
    .menu-item-row__title {
      @include text-truncate();
      width: $article-type-width - 10px;
    }
  }
}