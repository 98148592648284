.agentClassifier {
  &__header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close {
      font-size: 1.8rem;
      font-weight: 400;
    }

    .close:focus {
      outline:0;
    }
  }

  &__title {
    margin-top: 8px;
  }

  &__content {

  }

  &__spinButton {
    &[type=number] {
      padding-right: 0.2rem;
    }

    &[type=number]::-webkit-outer-spin-button,
    &[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: textarea;
    }
  }
}