@use "../../styles/common";

.menuItemRest {
  @include common.disable-default-button-styles;
  display: flex;
  justify-content: center;

  &__icon {
    font-size: common.$sidebar-menu-icon-size;
  }
}