

@mixin arrow-toggle-animation-open {
    .arrowToggleIcon__lineLeft {
        transform: translateX(2px) rotate(30deg);
    }
    .arrowToggleIcon__lineRight {
        transform: translateX(-2px) rotate(-30deg);
    }
}

@mixin arrow-toggle-animation-close {
    .arrowToggleIcon__lineLeft {
        transform: translateX(2px) rotate(-30deg);
    }
    .arrowToggleIcon__lineRight {
        transform: translateX(-2px) rotate(30deg);
    }
}

@mixin arrow-init {
    animation: none;
    height: 3px;
    width: 20px;
    transition: all .3s;
}

.arrowToggleIcon {

}

.arrowToggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 10px;
    cursor: pointer;
    &__icon {
        display: flex;
        flex-direction: row;
        .arrowToggleIcon__lineLeft {
            @include arrow-init
        }
        .arrowToggleIcon__lineRight {
            @include arrow-init
        }
    }
    &:hover {
        .arrowToggle__icon {
            &.arrowToggleIcon_opened {
                @include arrow-toggle-animation-close;
            }
            &.arrowToggleIcon_closed {
                @include arrow-toggle-animation-open;
            }
        }
    }
}