@import "../../styles/common";

.article-params {
  margin-bottom: 20px;

  &__item {
    transition: box-shadow .2s ease-in-out;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 23px;
    border-radius: 5px;

    &:hover {
      box-shadow: $hover-box-shadow;

      .article-params__drag-icon, .article-params__remove-icon {
        opacity: 1;
        pointer-events: auto;
      }
    }

    &_dragging {
      background-color: $body-bg;
      box-shadow: $hover-box-shadow;
    }
  }

  &__drag-icon {
    @include no-outline-focus();

    float: left;
    padding: 0 8px;
    color: $gray-500;
    font-size: 1rem;
    cursor: grab;
    margin-right: 16px;

    opacity: 0;
    pointer-events: none;
  }

  &__title {
    flex-basis: 200px;
  }

  &__value {
    width: 350px;
    margin: 0 20px 0 0;

    &:disabled {
      background-color: inherit;
    }

    .invalid-feedback {
      display: none;
    }
  }

  &__remove-icon {
    cursor: pointer;
    justify-self: flex-end;
    font-size: 18px;
    margin-left: auto;

    opacity: 0;
    pointer-events: none;
  }
}