@use "../../styles/common";

.fileAttachment {
    display: flex;
    align-items: center;

    &__icon {
        margin-right: 8px;
        color: common.$ct-gray-650;
    }

    @each $file, $value in common.$file-colors {
        &__icon_#{$file} {
            color: $value;
        }
    }

    &__title {
        word-break: break-word;
    }
}