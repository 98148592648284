@use "../../../../styles/common";

.form {
    display: flex;
    flex-direction: column;
    max-width: 410px;
    height: 100%;
    margin-bottom: 40px;

    &__title {
        margin-bottom: 20px;
        font-size: common.$fz-20;
        line-height: 2;
    }

    &__attachment {
        display: flex;
        gap: 8px;
    }

    &__icon {
        color: common.$ct-gray-600;
        cursor: pointer;
    }

    &__btn {
        margin-top: auto;
    }

    &__subgroup {
        padding-left: 30px;
    }

    &__article:global(.is-invalid) {
        cursor: pointer;
        background-position: right calc(1.2em + 0.1875rem) center;
    }

    :global(.custom-radio) {
        flex-shrink: 0;
    }
}
