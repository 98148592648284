.text-item-setting {
  padding: 0 0 10px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .close {
    font-size: 1.8rem;
    font-weight: 400;
  }

  .close:focus {
    outline: 0;
  }
}