@use "../../styles/common";

.updateAppContainer {
  width: 79px;
  height: auto;
  display: none;
  @include common.transition-all();
  &__icon {
    animation: pulse 1.5s infinite;
    color: common.$knowledge-base-article-text-font-size;
  }
  &__text {
    font-size: .8rem;
    text-align: center;
    line-height: 1.4;
  }
}

@keyframes pulse {
  0% {
     transform: scale(.85);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(.85);
  }
}
.updateAppContainer:hover {
  background-color: common.$ct-gray-150;
  color: var(--primary);
  cursor: pointer;
}
.updateAppContainerOpen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}