@import "../../styles/common";

.agent-schedule {
  &__header {
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close {
      font-size: 1.6rem;
      font-weight: 400;
      margin-right: 2px;
    }

    .close:focus {
      outline: 0;
    }
  }

  &__title {
    margin-top: 8px;
  }

  & + .agent-schedule {
    padding-top: 5px;
    border-top: 1px solid $ct-gray-450;
  }

  &__tag {
    @include selectedTag;
  }

  &__tags {
    margin-bottom: 15px;
  }

  &__time-input {
    &:global(.form-control) {
      padding: 0.375rem 0.56rem;
      width: 36px;
      margin: 0;
    }
  }

  &__time-checkbox {
    padding-top: 5px;
  }

  &__time {
    flex-wrap: nowrap;

    .form-control.is-invalid {
      background-image: none;
      padding-right: 0.75rem;
    }
  }
}