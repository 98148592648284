@use "../../../../../../styles/common";

.dialogEmailMessageText {
  white-space: break-spaces;

  &__subject {
    font-weight: 900;
    margin-bottom: 20px;
  }

  > a {
    color: common.$rme-link;
  }
}