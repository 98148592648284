@import "../../styles/common";

.sidebarContextMenu {
  padding: 5px;
  border-radius: $border-radius;
  @include transition();

  &:hover {
    background-color: $sidebar-hover-color;
  }
}