@use "../../../../styles/common";

.article {
    border-bottom: 1px solid common.$ct-gray-300;
    padding: 15px 0;

    &:hover {
        cursor: pointer;
    }

    &__header {
        margin-bottom: 8px;
    }

    &__body {
        margin-bottom: 10px;
        max-height: 150px;
        overflow-y: hidden;
        color: common.$knowledge-base-sub-text;
        word-break: break-word;
    }

    &__header,
    &__body {
        em {
            background-color: common.$ct-yellow-2;
            padding: 2px 0;
            font-style: normal;
        }
    }

    &__footer {
        display: flex;
        padding: 5px 0;
    }

    &__modificationDate {
        margin-left: auto;
        color: common.$knowledge-base-sub-text;
        font-size: common.$fz-12;
    }
}
