@use "../../../styles/common";

.notification {
    background: #fff;
    &__icon {
        width: 21px;
        height: 28px;

        & > path {
            fill: common.$ct-blue-4;
        }
    }

    &__title {
        font-size: common.$fz-16;
        font-weight: 500;
    }

    &__content {
        margin-top: 10px;
    }

    &__progress {
        margin-top: 12px;
        height: 8px;
    }

    &__error {
        color: common.$ct-red-4;

        & > path {
            fill: common.$ct-red-4;
        }
    }
}
