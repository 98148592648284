@import "../../styles/common";

$title-font-size: $ct-font-size-base * 1.43;

.alertDialog {
  width: 410px;
  margin-top: 15%;

  .modalContent {
      border-radius: 12px;
      border-width: 0;
  }

  .modalBody {
    padding: 20px;
  }

  .modalTitle {
    font-weight: 500;
    font-size: $title-font-size;
    line-height: 22px;
  }

  .modalFooter {
    border-top: none;
    padding: 0 20px 20px;
  }
}
