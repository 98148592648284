@use "../../../styles/common";

.notification {
  &__title {
    font-weight: 500;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 5px 0 16px 0;
    gap: 8px;
  }

  &__time {
    color: common.$ct-gray-650;
    font-size: common.$fz-12;
  }

  &__progress {
    width: 100%;
    height: 8px;

    :global(.progress-bar) {
      border-radius: 24px;
      height: 8px;
      background-color: var(--primary);
    }
  }
}