@use "../../styles/common" as common;

$article-read-confirmation-card-title-font-size: common.$ct-font-size-base * 1.71;

.articleReadConfirmationCard {
  padding: 30px 70px;
  box-shadow: common.$hover-box-shadow;
  border-radius: 12px;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  &__title {
    font-size: $article-read-confirmation-card-title-font-size;
  }

  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__confirmIcon {
    margin-right: 10px;
  }
}