@use "../../styles/common";

.articleTableOfContent {
    $self: &;
    display: none;
    user-select: none;

    &__dropdown {
        margin-top: 6px;
        overflow: auto;
        max-height: 450px;
    }

    &__scrollable {
        gap: 10px;
        display: flex;
        flex-flow: column;
    }

    &__item {
        font-size: 1rem;
        cursor: pointer;
        word-wrap: break-word;
        transition: all 150ms ease;
        padding: 4px 16px;
        background-color: #fff;
        transition: all 130ms ease;

        &:hover {
            background-color: common.$ct-gray-175;
        }

        &_active {
            background-color: common.$ct-gray-175;
            color: var(--primary);
        }

        &_hidden {
            opacity: .5;
            pointer-events: none;
        }

        &_H2Heading {
            font-size: .9375rem
        }

        &_H3Heading {
            font-size: .875rem;
        }

        &_root {
            font-weight: 600;
        }

        &_marginX1 {
            padding-left: 36px;
        }

        &_marginX2 {
            padding-left: 56px;
        }
    }

    &_sticked {
        position: fixed;
        z-index: 1
    }

    &_visible {
        display: flex
    }

    #{$self}__overlay {
        padding: 0 0 16px;

        &_header {
            padding: 0 16px;
        }
    }
}

.intersectAreas {
    &__line {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(255,0,0,.3);
        z-index: 1000;
        color: #fff;
        font-size: 22px;
    }

}