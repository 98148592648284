
.userCardLoader {
  &__picture {
    width: 40px;
    overflow: hidden;
  }

  &__info {
    width: 100%;
  }

  &__introduction {
    height: 20px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
  }

  &__meta {
    width: 50%;
    height: 10px;
    margin-left: 2px;
    border-radius: 5px;
    overflow: hidden;
  }
}