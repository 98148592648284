@import "../../styles/common";

.project-wizard {
  .public-page {
    &__header {
      z-index: 1;
    }

    &__body {
      margin: -40px 0 0;
      display: flex;
      height: 100%;
      flex: 1;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    background: none;
    min-width: $wizard-sidebar-width;

    @media (max-width: 1024px) {
      visibility: hidden;
    }
  }
}