@use "../../../../styles/common";

.item {
    padding: 6px 16px;
    transition: background-color 0.15s;
    font-size: common.$fz-16;
    line-height: 1.1;

    &:hover {
        background-color: common.$ct-gray-175;
    }

    &__title {
        font-weight: 500;
        margin-bottom: 8px;
    }
}