@import "../../styles/common";

.article-user-views-button {
  @include article-action();
  color: $knowledge-base-sub-text;
  border: none;

  &__users-count {
    @include article-action();
  }

  &__count {
    margin-right: 5px;
  }

  &__confirms {
    margin-left: 10px;
  }
}