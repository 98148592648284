@use "../../styles/common" as common;


.cancelButton {
    margin-left: 14px;
    width: 155px;

    &__icon {
      margin-right: 12px;
    }

    &:hover {
      color: common.$primary;
      cursor: pointer;
    }
}