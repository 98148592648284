@use "../../../../../styles/common";

.message {
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
        padding: 2px 8px;
        font-size: common.$fz-14;
        color: common.$ct-gray-600;
    }

    &__icon {
        margin: 4px 4px 0 4px;
    }

    &__iconPerson {
        margin: 4px 5px 0 4px;
    }

    &.taskCardContainer {
        .message__text {
            padding: 0;
            color: common.$ct-black-50;
        }

        .message__icon {
            margin-right: 8px;
        }

        .message__iconPerson:not(.message__iconSupervisor) {
            margin-right: 9px;
        }
    }
}

// div для повышения веса селектора, чтобы оверрайдить класс из MessageList (.messageList__item)
div.message {
    margin: 30px 0 4px 0;

    &_confirmation {
        margin: 0 0 30px 0;
    }
}