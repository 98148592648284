@import "styles/fonts";
@import "styles/animations";
@import "styles/common";
@import "styles/spins";
@import "styles/scrollbar";
@import "~bootstrap/scss/utilities";
@import "styles/bootstrap-components";
@import "styles/bootstrap-component-overrides";

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";

@import "~react-datepicker/dist/react-datepicker.css";

@import "styles/branding";

.hidden {
    display: none;
}

.ctBody {
    overflow: hidden;

    &_scroll {
        overflow: auto;
    }
}

// css-variables
:root {
    --ct-primary: #1066a8;
    --ct-secondary: #f07f0e;
    --header-height: 50px;

    //constants for the entire project
    --primary: var(--ct-primary);
    --secondary: var(--ct-secondary);
}
