@use "../../../../../styles/common" as common;

.webchatCard {
  margin-bottom: 36px;
  width: 442px;
  height: 316px;
  &__header {
    background-color: var(--primary);
    color: common.$white;
    display: flex;
    justify-content: space-between;
  }
  &__headerContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: .9rem;
  }
  &__closeIcon {
    margin-left: 15px;
    color: common.$white;
    padding: 0;
  }
  &__content {
    width: 100%;
    :global(.info-card__type) {
      font-size: .9rem;
      font-style: normal;
      font-weight: 400;
      color: common.$ct-black-50;
    }
  }
  &__bottons {
    display: flex;
    justify-content: flex-end;
    margin-left: 16px;
    margin-top: 16px;
    &__botton {
      margin-left: 16px;
    }
  }
}