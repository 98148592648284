@import "../../../../../styles/common";

.keyboard-button-row {
  display: flex;
  width: 100%;
  align-items: center;

  &__add-icon {
    @include transition();
    opacity: 0;
    font-size: 14px;
    padding: 2px 6px;
    pointer-events: none;
    margin-left: 8px;
  }

  &__btn {
    flex-grow: 1;
    flex-basis: 0;


    height: 100%;
  }

  &_add-allowed:hover {
    .keyboard-button-row {
      &__add-icon {
        opacity: 1;
        pointer-events: auto;
        cursor: pointer;
      }
    }
  }
}