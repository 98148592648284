@import "../../styles/common";
.projectView {
    background-color: $main-container-background;
    padding-left: $sidebar-width;
    @include transition-all();

    &_sidebarOpened {
        padding-left: $sidebar-opened-width;
    }

    &__sidebar {

    }

    &__content {
        min-height: $height-without-header;
        overflow-y: hidden;
    }
    &__container {

    }
    &__row {

    }
    &__dialogsCounter {
        background-color: var(--primary) !important;
        padding: 2px 4px;
        width: 16px;
        height: 16px;
        position: absolute;
        top: calc(var(--header-height) + 16px);
        left: 25px;
        z-index: 1;
    }
}