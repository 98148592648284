@use "../../../../styles/common";

.list {
    margin: -8px -12px;
    padding: 10px 0;
    list-style-type: none;
    overflow-y: auto;
    max-height: 600px;
    @include common.mini-scrollbar();
}
