@import "../../styles/common";

$task-info-title-font-size: $ct-font-size-base * 1.125;
$task-info-id-font-size: $ct-font-size-base * .875;
$task-info-channel-icon-size: $ct-font-size-base * 1.1875;
$tooltip-background-color: white;

.task-info {
    &__section {
        margin-bottom: 16px;
        border-bottom: 1px solid $ct-gray-300;

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }

    &__group {
        display: flex;
        justify-content: flex-start;

        > * {
            margin-right: 97px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .info-field {
        margin-bottom: 16px;
    }

    &__route-popover {
        font-size: inherit;

        &:global(.popover) {
            max-width: initial;
            z-index: 4000;
        }
    }

    &__route {
        margin-bottom: 25px;
    }

    &__card {
        max-width: 280px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 5px;
        border-radius: 5px;
        color: #fff;
    }

    &__card-icon {
        font-size: $task-info-channel-icon-size;
        margin-right: 5px;
    }

    &__card-name {
        font-size: $task-info-id-font-size;
    }

    &__agent {
        background-color: $ct-gray-600;
        color: $white
    }

    &__id {
        margin-top: 0.8rem;
    }
}

@each $channel, $value in $channel-colors {
    .task-info__channel-#{$channel} {
        background-color: $value;
    }
}