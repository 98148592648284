@use "../../styles/common";

.queueForAutomaticProcessing {
    &__button {
        padding: 0;
    }
    &__loader {
        display: flex;
        padding: 40px 20px;
    }
}