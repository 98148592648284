@use "../../styles/common" as common;

$icon-color: rgba(16, 102, 168, 0.3);
$icon-img-size: 50px;
$placeholder-img: 170px;

.imageUploadPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  border: 1px dashed $icon-color;
  flex-direction: column;
  position: relative;

  height: $placeholder-img;
  width: $placeholder-img;
  border-radius: 5px;

  &_inline {
    height: $icon-img-size;
    width: $icon-img-size;
  }

  &__img {
    height: $placeholder-img;
    width: $placeholder-img;
    border-radius: 5px;
    object-fit: contain;
    transition: common.$transition-base;

    &_inline {
      height: $icon-img-size;
      width: $icon-img-size;
    }
  }

  &:hover &__img {
    opacity: 0.4;
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: common.$transition-base;
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover &__overlay {
    opacity: 1;
  }

  &__btn {
    color: var(--primary);
  }

  &__icon {
    color: $icon-color;
    font-size: 48px;
    margin-bottom: 16px;

    &_inline {
      font-size: 1.9rem;
      margin-bottom: 0;
    }
  }
}
