@import "../../styles/common";

.settingsContextMenu {
    padding: 0;
    border-radius: $border-radius;
    overflow: hidden;

    &__container {
        width: auto;
        display: inline-block;
    }
}