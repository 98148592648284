@import "../../styles/common";

$public-page-message-icon-size: 45px;
$public-page-title-font-size: $ct-font-size-base * 1.4;

.public-page {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: {
        left: 60px;
        right: 60px;
        top: 40px;
        bottom: 40px;
    };
    $self: &;
    &_centered {
        #{$self}__header {
            margin-bottom: auto;
        }
    }
    &__header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        .header__left {

        }
        .header__right {
            margin-left: auto;
            .language-container {
                bottom: 7px;
                top: auto;
            }
        }
    }
    &__title {
        font-size: $public-page-title-font-size;
        color: $sub-text-color;
        margin-bottom: 0;
    }
    &__body {
        width: 100%;
        margin: {
            top: 30px;
            bottom: 30px;
        };
    }
    &__footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: auto;
        .footer__left {

        }
        .footer__right {
            margin-left: auto;
        }
    }
    &-message {
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & &__icon {
            width: $public-page-message-icon-size;
            height: $public-page-message-icon-size;
            margin-bottom: 20px;
        }
        &__title {
            margin-bottom: 20px;
        }
        &__image {
            margin-top: 40px;
            user-select: none;
        }

        &__text {
            font-size: $ct-font-size-base * 1.14;
        }
    }
}