@import "../../styles/common";

.sign-up-form {
    @include public-form;
    margin-bottom: 0;

    &__project {
        max-width: $public-form-default-width;
        width: auto;
        box-shadow: $highlight-box-shadow;
        justify-content: center;
        align-items: center;
        padding: {
            left: 20px;
            right: 20px;
            top: 20px;
            bottom: 20px;
        };
        margin: {
            bottom: 40px;
        };
    }
}