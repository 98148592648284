@import "../../styles/common";

$section-margin-bottom: 1.5rem;
$queue-form-font-size: $ct-font-size-base;

.individual-queue-form {
    @include page-layout-sidebar();
    font-size: $queue-form-font-size;

    &__toggle {
        margin-bottom: $section-margin-bottom;
        display: flex;
        align-items: center;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .form-group {
        margin-bottom: $section-margin-bottom;
    }
}