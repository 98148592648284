@import "../../styles/common";

$gap: 20px;

.settingsGroupContent {
    @include page-layout-sidebar();

    &__btnIcon {
        margin-right: 10px;
    }

    &__buttons {
        display: flex;
        row-gap: 15px;
        flex-wrap: wrap;
        max-width: 320px;
        padding-top: 10px;
    }

    &__btn:not(:last-child) {
        margin-right: 10px;
    }

    &__inputsContainer {
        margin-top: $gap;
        &_empty {
            margin-top: 5px;
        }
    }

    &__checkBoxInput {
        display: flex;
        justify-content: space-between;
        margin-bottom: $gap;
    }

    &__checkBox {
        &_disabled {
            pointer-events: none;

            > label::before {
                opacity: 0.6;
            }
        }
    }

    &__deletePermissionBtn {
        color: $ct-gray-600;
    }

    &__addPermissionBtn {
        margin-bottom: 17px;
    }
}