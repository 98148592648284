@use "../../styles/common";

.permission {
    display: flex;
    align-items: center;

    &__card {
        flex: 1;
    }

    &__project {
        color: common.$knowledge-base-sub-text;
        font-size: common.$fz-12;
        line-height: 14 / 12;
    }

    &__remove {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        font-size: common.$fz-18;
    }
}
