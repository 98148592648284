@use "../../../styles/common" as common;

$task-info-title-font-size: common.$fz-16;
$task-info-id-font-size: common.$fz-16;
$task-info-channel-icon-size: common.$fz-16;

.queuePendingTasksTable {
  padding: 20px;

  :global(.settings-table__body) {
    thead th, tbody td {
      &:last-child {
        text-align: left;
      }
    }
  }

  &__userCell {
    display: flex;
    flex-direction: column;
    padding: 13px 0;
  }

  &__taskPreview {
    @include common.text-truncate;

    color: common.$ct-gray-600;
    margin-left: 49px;
  }

  &__cardContainer {
    display: flex;
  }

  &__card {
    max-width: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 5px;
    border-radius: 5px;
    color: white;
  }

  &__cardName {
    font-size: $task-info-id-font-size;
  }

  &__cardNameIcon {
    font-size: $task-info-channel-icon-size;
    margin-right: 5px;
  }

  @each $channel, $value in common.$channel-colors {
    .task-info__channel-#{$channel} {
      background-color: $value;
    }
  }
}